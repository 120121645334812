<template>
  <widget-wrapper
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        {{ widgetSubtitle }}
        <font-awesome-icon
          v-if="canNavigate"
          class="link-icon"
          icon="chart-bar"
        />
      </div>
      <div class="widget-count">
        {{ count }} responses
      </div>
    </div>
    <div class="themes-body widget-body">
      <div
        v-if="hasComparisonThemes"
        class="legend"
      >
        <svg
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.primary500"
          />
        </svg>
        <span class="key-text">{{ data.baseline.name }}</span>
        <svg
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.orange500"
          />
        </svg>
        <span class="key-text">{{ data.comparison.name }}</span>
      </div>
      <div
        v-for="(theme, index) in themes"
        :key="index + theme.name"
        class="theme-row"
      >
        <div
          v-if="!isWeighted"
          :class="{ 'clickable-theme': canClickTheme }"
          class="theme-name"
          @click="doNavigate({code: theme.code, title: theme.name}, null, { volumeBy })"
        >
          <span>{{ theme.name }}</span>
        </div>
        <theme-name-with-sources
          v-else
          :source="source"
          tool="explore"
          :theme="theme.name"
          :theme-components="theme.components"
          :theme-name="theme.name"
          :theme-sources="themeSources"
        />
        <bar-difference
          v-if="hasComparisonThemes"
          :value="theme.volumeBaseline"
          :old-value="theme.volumeComparison"
          :max-value="maxPercentage"
          suffix="%"
          dp="0"
        />
        <percentage-bar
          v-else
          :value="theme.volume"
          :max-value="maxPercentage"
          suffix="%"
        />
      </div>
    </div>
    <summary-component
      v-if="isSummaryEnabled"
      :filters="filters"
      :theme="selectedTheme"
      :source="source"
      :comparison-themes="comparisonThemes"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
    <comment-component
      v-if="isCommentsEnabled"
      :filter-string="filters ? filters.baseline : undefined"
      :theme="selectedTheme"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
  </widget-wrapper>
</template>

<script>
import { ReactInVue } from 'vuera';
import { each, get, slice } from 'lodash';

import { Bar } from 'components/Dashboard/Widgets/Bar';
import { BarDifference } from 'components/Dashboard/Widgets/BarDifference';
import ThemeNameWithSources from './Components/ThemeNameWithSources';

import { CommentComponent as ReactCommentComponent } from 'components/Dashboard/Widgets/CommentComponent';
import SummaryComponent from './Components/SummaryComponent';
import CanNavigateMixin from 'vue/dashboards/Dashboard/MixIns/CanNavigateMixin';
import SentimentMixin from 'vue/dashboards/Dashboard/MixIns/SentimentMixin';

import WidgetWrapper from './WidgetWrapper';
import colors from 'vue/styles/element-variables.scss';

export default {
  name: 'THEMES_SIMPLE',
  components: {
    BarDifference: ReactInVue(BarDifference),
    PercentageBar: ReactInVue(Bar),
    ThemeNameWithSources,
    WidgetWrapper,
    SummaryComponent,
    CommentComponent: ReactInVue(ReactCommentComponent),
  },
  mixins: [CanNavigateMixin('explore'), SentimentMixin],
  props: {
    config: { default: undefined, type: Object },
    filters: { default: undefined, type: Object },
    context: { default: undefined, type: Object },
    data: { default: undefined, type: Object },
    warning: { default: undefined, type: String },
    error: { default: undefined, type: String },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  data() {
    return {
      colors: colors,
    };
  },
  computed: {
    comparisonThemes() {
      return get(this, 'data.comparison');
    },
    themeSources() {
      return get(this, 'data.metadata.sources', undefined);
    },
    compareToOverall() {
      return get(this.config, 'compareToOverall', false);
    },
    hasComparisonThemes() {
      return this.compareToOverall && this.themes.length > 0 && this.themes[0].volumeComparison !== undefined;
    },
    themes() {
      if (!this.data) {
        return [];
      }
      const data = this.data;
      const themes = slice(data.themes, 0, this.limit);

      return themes;
    },
    maxPositive() {
      return this.calculateMaxPositive(this.themes);
    },
    maxPercentage() {
      let curr = 0;
      if (this.themes)
        each(this.themes, (theme) => {
          if (theme.volume) {
            curr = Math.max(theme.volume, curr);
          } else if (theme.volumeBaseline) {
            curr = Math.max(Math.max(theme.volumeBaseline, theme.volumeComparison), curr);
          }
        });
      return curr;
    },
    count() {
      if (this.hasComparisonThemes) {
        return get(this.data, 'baseline.count', 0);
      } else {
        return get(this.data, 'count', 0);
      }
    },
  },
  watch: {
    config: {
      handler (config) {
        this.isCommentsEnabled = config.showComments;
        this.isSummaryEnabled = config.showSummary;
      },
      immediate: true
    },
  },
};
</script>

<style lang="scss" scoped>
.key-square {
  height: 14px;
  display: inline-flex;
  margin-right: 5px;
  width: 14px;
}
.key-text {
  margin-right: 10px;
}
.widget-comparison-legend {
  align-items: center;
  display: flex;
}
.widget-count {
  align-self: flex-end;
}
</style>
