<template>
  <widget-wrapper
    :title="title"
    :loading="loading"
    :error="error"
    :warning="warning"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        {{ subtitle }}
        <font-awesome-icon
          v-if="canNavigate"
          class="link-icon"
          icon="chart-bar"
        />
      </div>
      <div class="widget-display-categories">
        <div class="category">
          Impact
        </div>
        <div class="category">
          Volume
        </div>
      </div>
    </div>
    <div
      :class="{ empty: empty }"
      class="themes-body widget-body"
    >
      <div
        v-for="(theme, index) in themes"
        :key="theme + index"
        class="theme-row"
      >
        <div
          v-if="!isWeighted"
          :class="{ 'clickable-theme': canClickTheme }"
          class="theme-name"
          @click="doNavigate({code: theme.code, title: theme.name})"
        >
          <span>{{ theme.name }}</span>
        </div>
        <theme-name-with-sources
          v-else
          :source="source"
          tool="explore"
          subtool="impact"
          :theme="theme.name"
          :theme-components="theme.components"
          :theme-name="theme.name"
          :theme-sources="themeSources"
        />
        <bar
          :value="theme.impact"
          :max-value="maxValue"
          :range="range"
          :display-percentage="theme.volume"
          :last-item="index + 1 == themes.length"
        />
      </div>
      <div
        v-if="empty"
        class="empty-theme-list"
      >
        {{ emptyMessage }}
      </div>
    </div>
    <summary-component
      v-if="isSummaryEnabled"
      :filters="filters"
      :theme="selectedTheme"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
    <comment-component
      v-if="isCommentsEnabled"
      :filter-string="filters ? filters.baseline : undefined"
      :theme="selectedTheme"
      :sentiment="sort === 'asc' ? 'negative' : 'positive'"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
  </widget-wrapper>
</template>

<script>
import { ReactInVue } from 'vuera';
import { Bar } from 'components/Dashboard/Widgets/Bar';
import ThemeNameWithSources from './ThemeNameWithSources';
import { CommentComponent as ReactCommentComponent } from 'components/Dashboard/Widgets/CommentComponent';
import SummaryComponent from './SummaryComponent';

import CanNavigateMixin from 'vue/dashboards/Dashboard/MixIns/CanNavigateMixin';

import WidgetWrapper from '../WidgetWrapper';

export default {
  name: 'IMPACTCOMPONENT',
  components: {
    Bar: ReactInVue(Bar),
    ThemeNameWithSources,
    WidgetWrapper,
    CommentComponent: ReactInVue(ReactCommentComponent),
    SummaryComponent
  },
  mixins: [CanNavigateMixin('explore', 'impact')],
  props: {
    filters: {type: Object},
    count: { type: Number },
    error: { type: String },
    warning: { type: String },
    loading: { default: false, type: Boolean },
    maxValue: { type: Number },
    range: { type: Array },
    scoreName: { type: String },
    showComments: { default: false, type: Boolean },
    showSummary: { default: false, type: Boolean },
    sort: { type: String },
    source: { type: String },
    title: { type: String },
    themes: { type: Array },
    themeSources: { type: Array },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  computed: {
    empty() {
      return !this.themes || !this.themes.length;
    },
    subtitle() {
      const { scoreName, sort } = this;
      const tokens = [];

      if (sort === 'asc') {
        tokens.push('Key negative drivers of');
      } else {
        tokens.push('Key positive drivers of');
      }

      if (scoreName) {
        tokens.push(scoreName);
      } else {
        tokens.push('score');
      }

      return tokens.join(' ');
    },
    emptyMessage() {
      if (this.sort === 'asc') {
        return 'No negative themes found';
      } else {
        return 'No positive themes found';
      }
    },
  },
  watch: {
    showComments: {
      handler (showComments) {
        this.isCommentsEnabled = showComments;
      },
      immediate: true
    },
    showSummary: {
      handler (showSummary) {
        this.isSummaryEnabled = showSummary;
      },
      immediate: true
    },
  },
};
</script>

<style lang="scss" scoped></style>

