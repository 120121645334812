<template>
  <div class="filter">
    <div class="header">
      <font-awesome-icon
        v-if="filterIcon"
        :icon="filterIcon"
        title="Select comparison"
      />

      {{ filterData.name }}
    </div>
    <el-popover
      ref="popover"
      :visible-arrow="false"
      :popper-class="popoverClass"
      placement="bottom-start"
      width="100%"
      trigger="click"
      @show="focusInput"
    >
      <el-input
        ref="inputElement"
        v-model="filterText"
        placeholder="Search"
        size="small"
        clearable
      />
      <ul
        v-if="hasFilterOptions"
        class="filter-list"
      >
        <li
          v-for="option in filterOptions"
          v-show="isVisible(option)"
          :key="option.name + '_' + option.id"
          @click="handleSelectionChange(option)"
        >
          <span
            :class="{ 'is-checked': isChecked(option) }"
            :aria-label="option.name"
            :aria-checked="isChecked(option)"
          >
            {{ option.name }}
          </span>
        </li>
      </ul>
    </el-popover>
    <el-button
      :id="buttonID"
      ref="button"
      v-popover:popover
      :type="filterKey"
      size="medium"
    >
      <span>
        {{ title }}
      </span>
      <font-awesome-icon icon="chevron-down" />
    </el-button>
  </div>
</template>


<script>
import _ from 'lodash';
import { matchesRoute } from 'vue/libs/filter-watcher';

export default {
  name: 'FilterDropdownList',
  props: {
    filterData: { type: Object },
    filterIcon: { type: String },
    filterKey: { type: String },
    popoverCls: { type: String },
    selectedFilters: { type: Object },
    multiSelect: { type: Boolean },
  },
  data() {
    return {
      filterText: '',
      title: '',
      buttonID: this._uid,
      selectedOptions: [],
    };
  },
  computed: {
    filterId() {
      return this.filterData && this.filterData.id;
    },
    popoverClass() {
      const tokens = ['popover-no-gap'];
      if (this.popoverCls) {
        tokens.push(this.popoverCls);
      }
      return tokens.join(' ');
    },
    filterOptions() {
      return (this.filterData.cuts || []).map((option) => {
        return {
          id: option.id,
          rql: option.rql,
          label: option.name,
          name: option.name,
        };
      });
    },
    hasFilterOptions() {
      return !_.isEmpty(this.filterOptions);
    },
  },
  watch: {
    selectedFilters: {
      handler(newFilter) {
        this.buildFromFilterData(newFilter);
      },
    },
    $route: {
      handler(to) {
        if (!to) {
          return;
        }
        const { filterKey, filterId } = this;
        // Set selected options based on the route, this happens when coming from a
        // theme on dashboards page with options pre-selected for filters, ex. Airline filter
        const selected = matchesRoute(to, filterKey, filterId);
        if (selected) {
          this.setFromIds(selected);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (_.isEmpty(this.selectedOptions) && this.hasFilterOptions) {
      const defaultOption = this.filterOptions[0];
      this.handleSelectionChange(defaultOption);
    }
  },
  methods: {
    onParentClick(e) {
      let { target } = e;

      while ((target = target.parentElement)) {
        if (target === this.$el) {
          return;
        }
      }

      // if we didn't find this element while walking up parents
      // it's an off-click
      this.$refs.popover.doClose();
    },
    focusInput: function () {
      // this is called in the middle of a transition so we should wait a bit
      const element = this.$refs.inputElement;
      setTimeout(function () {
        element.focus();
      }, 100);
    },
    handleSelectionChange(data) {
      this.setSelectedOptions(data);
      this.onSelectionChange(data);
    },
    setSelectedOptions(data) {
      // If multi select is chosen, do not add `All` option
      if (this.multiSelect && data.rql !== '') {
        // If multi select is chosen, remove `All` when
        // a different option other than `All` is selected
        if (this.selectedOptions[0].rql === '') {
          this.selectedOptions = [];
        }
        // Unchecking logic
        if (this.selectedOptions.find((option) => option.rql === data.rql)) {
          // If unchecking the only selected option, do not uncheck
          if (this.selectedOptions.length === 1) return;
          // Uncheck by removing the option from selected options
          this.selectedOptions = this.selectedOptions.filter((option) => option.rql !== data.rql);
        } else {
          this.selectedOptions = [...this.selectedOptions, data];
        }
      } else {
        this.selectedOptions = [data];
      }
    },
    onSelectionChange() {
      // Update title
      this.buildTitle(this.selectedOptions);

      // Update store
      const queryFilter = {
        filterKey: this.filterKey,
        filterId: this.filterData.id,
        filterName: this.filterData.name,
        filterType: this.filterType,
        selected: this.selectedOptions,
      };
      this.$emit('selectionChanged', queryFilter, this.title);
    },
    isChecked(option) {
      return _.some(this.selectedOptions, option);
    },
    isVisible(option) {
      return option.name.toLowerCase().includes(this.filterText.toLowerCase());
    },
    buildFromFilterData: function (data) {
      if (data && data[this.filterKey][this.filterData.id] && data[this.filterKey][this.filterData.id].selected) {
        this.selectedOptions = data[this.filterKey][this.filterData.id].selected.map((option) => ({
          id: option.id,
          rql: option.rql,
          label: option.name,
          name: option.name,
        }));
        this.buildTitle(this.selectedOptions);
      }
    },
    buildTitle(options) {
      const str = [];
      options.forEach((option) => {
        str.push(option.name);
      });
      this.title = str.join('/');
    },
    setFromIds(selected) {
      if (!_.isEmpty(selected)) {
        this.selectedOptions = [];
        selected.forEach((optionId) => {
          const selectedOption = this.filterOptions.find((filterOption) => filterOption.id === optionId);
          if (selectedOption) {
            this.selectedOptions = [...this.selectedOptions, selectedOption];
          }
        });
      }
      this.onSelectionChange();
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
@import '../../styles/filter-set-styles.scss';

.popover-no-gap {
  border-width: 2px;
  margin-top: 0px !important;
  width: 300px;
}

.filter {
  min-width: 200px;
}

.el-button {
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 10px;
  color: $--neutral-800;
  line-height: 22px;
  background-color: white;
  .dashboard .filter-item & {
    @extend .filter-button-base-style;
  }
  &--comparison {
    &:hover {
      color: inherit;
      background: $--red-100;
    }
  }
  ::v-deep span {
    align-items: center;
    display: flex;
    span {
      flex: 1 1 auto;
    }
    svg {
      flex: 0 0 auto;
      margin: 0 3px;
    }
  }
}

.popover {
  width: 300px;
}

.el-popover {
  ul {
    overflow: auto;
    max-height: 250px;
    padding: 0 10px;
  }

  li {
    padding: 7px 20px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background: $--neutral-100;
    }

    .is-checked {
      &:after {
        content: unset;
      }
      &:before {
        display: inline-block;
        margin-right: 5px;
        margin-left: -20px;
        content: '';
        width: 14px;
        height: 14px;
        background-image: url('data:image/svg+xml;utf8,<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></g></svg>');
      }

      font-weight: 600;
    }
  }
}
</style>
