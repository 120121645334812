<template>
  <div class="percentage-bar">
    <svg
      class="percentage-bar-indicator"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="overall">
          <stop
            offset="0%"
            :stop-color="colors.primary400"
          />
          <stop
            offset="100%"
            :stop-color="colors.primary300"
          />
        </linearGradient>
        <linearGradient id="comparison">
          <stop
            offset="0%"
            :stop-color="colors.orange400"
          />
          <stop
            offset="100%"
            :stop-color="colors.orange200"
          />
        </linearGradient>
        <linearGradient id="negative-overall">
          <stop
            offset="0%"
            :stop-color="colors.primary300"
          />
          <stop
            offset="100%"
            :stop-color="colors.primary400"
          />
        </linearGradient>
        <linearGradient id="negative-comparison">
          <stop
            offset="0%"
            :stop-color="colors.orange200"
          />
          <stop
            offset="100%"
            :stop-color="colors.orange400"
          />
        </linearGradient>
      </defs>
      <rect
        :x="x + '%'"
        :width="width + '%'"
        :fill="`url(#${isNegative ? 'negative-' : ''}overall)`"
        y="9"
        rx="0"
        ry="0"
        height="10"
      />
      <rect
        :x="xComparison + '%'"
        :width="comparisonWidth + '%'"
        :fill="`url(#${isNegative ? 'negative-' : ''}comparison)`"
        y="23"
        rx="0"
        ry="0"
        height="10"
      />
      <rect
        :x="pathCenter +'%'"
        y="0"
        rx="0"
        ry="0"
        width="1"
        height="40"
        :fill="colors.neutral600"
      />
    </svg>
    <div class="baseline">
      <span
        class="value-label"
        aria-label="baseline value"
      >>{{ baseline }}</span>
      <span
        v-if="displayPercentage !== undefined"
        class="percentage-label"
      > {{ baselineVolume }}</span>
    </div>
    <div class="comparison">
      <span
        class="value-label"
        aria-label="comparison value"
      >{{ comparison }}</span>
      <span
        v-if="displayPercentage !== undefined"
        class="percentage-label"
      > {{ comparisonVolume }}</span>
    </div>
  </div>
</template>

<script>
import toFixed from 'vue/libs/to-fixed';
import colors from 'vue/styles/element-variables.scss';

function format(value, precision = 1, suffix = '') {
  const prefix = value < 0 ? '' : '+';
  if (precision === 0) {
    return prefix + toFixed(Math.round(value), 0) + suffix;
  }
  return prefix + toFixed(value, precision) + suffix;
}
export default {
  name: 'BarComparison',
  props: {
    value: { default: undefined, type: Number },
    range: { default: () => undefined, type: Array },
    comparisonValue: { default: undefined, type: Number },
    suffix: { default: undefined, type: String },
    dp: { default: 1, type: Number },
    maxPositive: { default: undefined, type: Number },
    maxNegative: { default: undefined, type: Number },
    lastItem: { default: false, type: Boolean },
    displayPercentage: { default: undefined, type: Number },
    comparisonDisplayPercentage: { default: undefined, type: Number },
  },
  data() {
    return {
      colors: colors
    }
  },
  computed: {
    isNegative() {
      return this.value < 0;
    },
    totalDifference() {
      const difference =
        Math.abs(this.maxNegative) + Math.abs(this.maxPositive);
      return difference;
    },
    pathCenter() {
      return (0.1 + this.xOffset) * 90;
    },
    xOffset() {
      return Math.abs(this.maxNegative / this.totalDifference);
    },
    baseline() {
      return format(this.value, this.dp, this.suffixLabel);
    },
    comparison() {
      return format(this.comparisonValue, this.dp, this.suffixLabel);
    },
    baselineVolume() {
      return this.displayPercent(this.displayPercentage);
    },
    comparisonVolume() {
      return this.displayPercent(this.comparisonDisplayPercentage);
    },
    width() {
      // normalized to percentage but only of a total of 90% of the space
      const width = this.getWidth(this.value);
      return Math.abs(width) * 90;
    },
    comparisonWidth() {
      const width = this.getWidth(this.comparisonValue);
      return Math.abs(width) * 90;
    },
    x() {
      const x = this.getX(this.value);
      return x * 90;
    },
    xComparison() {
      const x = this.getX(this.comparisonValue);
      return x * 90;
    },
    suffixLabel() {
      let suffixLabel = this.suffix;
      if (this.range && !this.suffix) {
        suffixLabel = '%';
      }
      return suffixLabel;
    }
  },
  methods: {
    getWidth(val) {
      return val / this.totalDifference;
    },
    getX(value) {
      return 0.1 + this.xOffset + this.getWidth(value > 0 ? 0 : value);
    },
    displayPercent(value) {
      const { dp } = this;
      if (value === undefined) {
        return '';
      }
      if (dp == 0) {
        return toFixed(Math.round(value), 0) + '%';
      }
      return toFixed(value, dp) + '%';
    },
  }
};
</script>


<style lang="scss" scoped>
.percentage-bar {
  display: grid;
  grid:
    'bar-inner baseline' 20px
    'bar-inner comparison' 20px
    / auto 120px;
}
.percentage-bar-indicator {
  display: block;
  height: 40px;
  width: 100%;
  grid-area: bar-inner;
}
.value-label,
.percentage-label {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  width: 60px;
  text-align: right;
}
.baseline {
  align-self: flex-end;
  grid-area: baseline;
  display: inline-flex;
}
.comparison {
  align-self: flex-start;
  grid-area: comparison;
  display: inline-flex;
}
</style>
