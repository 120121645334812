import { IntegrationType } from 'api/enums';
import AircallLogo from 'images/aircall-logo.svg';
import AlchemerLogo from 'images/alchemer-logo.png';
import AppstoreLogo from 'images/appstore-logo.svg';
import AskNicelyLogo from 'images/asknicely-logo.png';
import ConfirmitLogo from 'images/confirmit-logo.png';
import DelightedLogo from 'images/delighted-logo.png';
import DiscordLogo from 'images/discord-logo.svg';
import DiscourseLogo from 'images/discourse-logo.svg';
import FeefoLogo from 'images/feefo-logo.png';
import GongLogo from 'images/gong-logo.svg';
import GoogleBigQueryLogo from 'images/googlebigquery-logo.svg';
import GooglePlayLogo from 'images/googleplay.svg';
import GoogleSheetsLogo from 'images/googlesheets-logo.png';
import GorgiasLogo from 'images/gorgias-logo.png';
import IntercomLogo from 'images/intercom-logo.svg';
import JotformLogo from 'images/jotform-logo.svg';
import QualtricsLogo from 'images/qualtrics-logo.svg';
import QuestionProLogo from 'images/questionpro-logo.png';
import RedditLogo from 'images/reddit-logo.svg';
import ReevoLogo from 'images/reevoo-logo.svg';
import SalesforceLogo from 'images/salesforce.svg';
import SlackLogo from 'images/slack-logo.svg';
import SnowflakeLogo from 'images/snowflake-logo.svg';
import SurveyMonkeyLogo from 'images/surveymonkey.svg';
import SurvicateLogo from 'images/survicate-logo.svg';
import TrustPilotLogo from 'images/trustpilot-logo.png';
import TwitterLogo from 'images/twitter-logo.svg';
import TypeformLogo from 'images/typeform-logo.svg';
import YabbleLogo from 'images/yabble-logo.svg';
import YotpoLogo from 'images/yotpo-logo.svg';
import ZendeskLogo from 'images/zendesk-logo.svg';

const INTEGRATION_LOGOS = {
  [IntegrationType.AIRCALL]: AircallLogo,
  [IntegrationType.ASKNICELY]: AskNicelyLogo,
  [IntegrationType.CONFIRMIT]: ConfirmitLogo,
  [IntegrationType.DELIGHTED]: DelightedLogo,
  [IntegrationType.DISCORD]: DiscordLogo,
  [IntegrationType.FEEFO]: FeefoLogo,
  [IntegrationType.GONG]: GongLogo,
  [IntegrationType.GOOGLEPLAY]: GooglePlayLogo,
  [IntegrationType.GOOGLEBIGQUERY]: GoogleBigQueryLogo,
  [IntegrationType.GOOGLESHEETS]: GoogleSheetsLogo,
  [IntegrationType.GORGIAS]: GorgiasLogo,
  [IntegrationType.INTERCOM]: IntercomLogo,
  [IntegrationType.JOTFORM]: JotformLogo,
  [IntegrationType.QUALTRICS]: QualtricsLogo,
  [IntegrationType.QUESTIONPRO]: QuestionProLogo,
  [IntegrationType.REEVOO]: ReevoLogo,
  [IntegrationType.SLACK]: SlackLogo,
  [IntegrationType.SNOWFLAKEREADER]: SnowflakeLogo,
  [IntegrationType.SALESFORCE]: SalesforceLogo,
  [IntegrationType.SURVEYGIZMO]: AlchemerLogo,
  [IntegrationType.SURVEYMONKEY]: SurveyMonkeyLogo,
  [IntegrationType.SURVICATE]: SurvicateLogo,
  [IntegrationType.TRUSTPILOT]: TrustPilotLogo,
  [IntegrationType.TYPEFORM]: TypeformLogo,
  [IntegrationType.YABBLE]: YabbleLogo,
  [IntegrationType.YOTPO]: YotpoLogo,
  [IntegrationType.ZENDESK]: ZendeskLogo
  // Data sources
  ,
  [IntegrationType.APPSTORE]: AppstoreLogo,
  [IntegrationType.DISCOURSE]: DiscourseLogo,
  [IntegrationType.REDDIT]: RedditLogo,
  [IntegrationType.TWITTER]: TwitterLogo,
};

export default INTEGRATION_LOGOS;
