<template>
  <div
    ref="bar"
    :class="{ large: large }"
    class="segmented-bar"
  >
    <div />
    <el-tooltip
      v-for="(segment, index) in segments"
      :key="index"
      :open-delay="300"
      placement="top"
    >
      <div slot="content">
        {{ segment.label }}
        <br>
        <div
          v-if="showCommentCount"
          class="comment-count"
        >
          {{ segment.count }} comment<span v-if="segment.count !== 1">s</span>,
        </div>
        {{ percentage(segment) }}
      </div>
      <div
        ref="segments"
        :style="bg(segment)"
        class="segmented-bar__segment"
      >
        <div class="segmented-bar__label">
          {{ segment.label }}
        </div>
        <span>{{ percentage(segment) }}</span>
      </div>
    </el-tooltip>
    <div />
  </div>
</template>

<script>
import { forEach, map, sum } from 'lodash';
import toFixed from 'vue/libs/to-fixed';
export default {
  name: 'SegmentedBar',
  props: {
    max: { default: 0, type: Number },
    min: { default: 0, type: Number },
    score: { default: 0, type: Number },
    segments: { default: () => [], type: Array },
    large: { default: false, type: Boolean },
    showCommentCount: { default: true, type: Boolean }
  },
  data() {
    return {
      widthsTimeout: undefined
    };
  },
  computed: {
    areas() {
      const { segments } = this;
      const areas = map(segments, (s, index) => `segment-${index + 1}`);
      areas.unshift('neg-space');
      areas.push('pos-space');
      return areas;
    },
    columns() {
      const { score, segments, min, sum } = this;
      const { range } = this;

      // The total width is made up of 4 fr, 3 for the bar and 1 for spacing around it
      const barWidth = 3;
      const widths = map(segments, segment => {
        const { count } = segment;
        if (count === 0) {
          return 0;
        } else {
          return `${((barWidth * count) / sum).toFixed(3)}fr`;
        }
      });

      // Logic to displace the bar
      if (range === 0) {
        widths.unshift('0');
        widths.push('0');
      } else {
        // normalise the value to be between 0 and 1
        // (value - min) / (max - min)
        // max - min is computed as range
        // normalisation handles negative score values for positive displacement
        const normalisedOffset = Math.abs((score - min) / range);

        // minOffset and maxOffset is the space before and after the bar
        // They should have a combined width of 1fr
        const offsetWidth = 1;
        const minOffset = Math.min(offsetWidth, normalisedOffset);
        const maxOffset = Math.max(0, offsetWidth - minOffset);

        widths.unshift(`${minOffset.toFixed(3)}fr`);
        widths.push(`${maxOffset.toFixed(3)}fr`);
      }

      return widths.join(' ');
    },
    scoreLabel() {
      const { score } = this;
      const label = toFixed(score, 1);
      if (score > 0) {
        return `+${label}`;
      } else {
        return label;
      }
    },
    range() {
      const { min, max } = this;
      return max - min;
    },
    sum() {
      const { segments } = this;
      return sum(map(segments, 'count'));
    }
  },
  mounted() {
    const areas = `"${this.areas.join(' ')}"`;
    this.$watch(
      'columns',
      columns => {
        this.$refs.bar.setAttribute(
          'style',
          `grid-template-columns: ${columns};grid-template-areas:${areas}`
        );
      },
      { immediate: true }
    );
    this.checkWidths();
    window.addEventListener('resize', this.checkWidths);
  },
  destroyed() {
    window.removeEventListener('resize', this.checkWidths);
  },
  updated() {
    this.checkWidths();
  },
  methods: {
    bg(segment) {
      const { color, colorEnd } = segment;
      const background = `linear-gradient(to right, ${color}, ${colorEnd})`;
      return { background };
    },
    percentage(segment) {
      const { sum } = this;
      if (sum === 0) {
        return 0;
      }
      return ((100.0 * segment.count) / sum).toFixed(1) + '%';
    },
    toggleVis(segmentWidth, label) {
      if (label && label.classList) {
        label.style.visibility = 'hidden';
        label.style.setProperty('display', 'block', 'important');

        const { width } = label.getBoundingClientRect();
        if (width >= segmentWidth) {
          label.classList.add('hidden');
        } else {
          label.classList.remove('hidden');
        }
        label.style.display = '';
        label.style.visibility = '';
      }
    },
    checkWidths() {
      clearTimeout(this.widthsTimeout);

      this.widthsTimeout = setTimeout(() => {
        forEach(this.$refs.segments, el => {
          const { width: segmentWidth } = el.getBoundingClientRect();

          const span = el.querySelector('span');
          this.toggleVis(segmentWidth, span);
          const label = el.querySelector('.segmented-bar__label');
          this.toggleVis(segmentWidth, label);
        });
      }, 50);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/element-variables.scss';

.segmented-bar {
  align-items: stretch;
  display: grid;
  grid: 'score neg-space detractors passives promoters pos-space' 17px / 1fr 1fr 1fr 1fr 1fr;
  z-index: var(--z-index-segmented-bar);
  &.large {
    grid-template-rows: 30px;
    .segmented-bar__label {
      color: $--neutral-500;
      display: block;
      font-size: 10px;
      line-height: 14px;
      position: absolute;
      bottom: -14px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      &.hidden {
        display: none;
      }
    }
  }
  .segmented-bar__label {
    display: none;
  }
  .segmented-bar__segment {
    position: relative;
    @media (max-width: $--md) {
      display: none;
    }
    span {
      font-size: 12px;
      font-weight: 600;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      &.hidden {
        display: none;
      }
    }
  }
}
</style>
