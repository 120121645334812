import * as workerPath from 'file-loader?name=[name].[hash].js!components/ThemeEditor/CommentMatcher.worker';
import { getContextStringFromTerms } from 'lib/string-helper';
import { getThemesStore } from 'stores/RootStore';
import { toDataSourceUrl } from 'stores/surveys/config-parser';
import { PlainComment, Thread } from 'types/custom';
import thematicData from 'vue/libs/thematicData';

export async function generateExampleComments(phrases: string[]) {
  const themesStore = getThemesStore();
  const commentWorker = new Worker(workerPath.default);

  let workerPromise = new Promise<string[]>((resolve) => {
    commentWorker.onmessage = (ev) => {
      const exampleComments = ev.data['comments'] as string[];
      const updatedExampleComments = exampleComments.map((val) => {
        return getContextStringFromTerms(val, phrases);
      });
      resolve(updatedExampleComments);
    };
  });

  if (commentWorker) {
    const resultsSize = 5;
    commentWorker.postMessage({
      comments: [...themesStore.exampleComments],
      phrases,
      invertResults: false,
      resultsSize
    });
  }

  let comments: string[] = await workerPromise;

  // if there are no comments in the first set then we look for comment in every set
  if (comments.length === 0 && phrases.length > 0) {
    // create rql for the first 20 phrases. We can't have everything!
    const selectedPhrasesRql = phrases.slice(0, 20).map(
      (text) => `${ 'responsetext' }==${ encodeURIComponent(text) }`).join(',');
    const requestOptions = {
      dataSource: toDataSourceUrl(themesStore.exampleCommentsLoadedFor.surveyId, '_') + '/',

    };
    const newComments: PlainComment[] | Thread[] = await thematicData.getComments(
      selectedPhrasesRql,
      themesStore.exampleCommentsLoadedFor.columns ? [themesStore.exampleCommentsLoadedFor.columns] : null,
      requestOptions,
      { page: 1, pageSize: 5 }
    );
    comments = newComments.map((comment: PlainComment | Thread) => {
      const c: string = typeof comment.comment === 'string'
        ? comment.comment
        : comment.comment.reduce((result: Array<string>, value): Array<string> => {
          // check if this part has any of the provided phrases in it
          const commentContainsPhrase = phrases.reduce((containsResult, p) => {
            return value.text.includes(p) || containsResult;
          }, false);
          if (commentContainsPhrase) {
            result.push(value.text);
          }
          return result;
        }, []).join('\n');

      return getContextStringFromTerms(c, phrases);
    });
  }

  if (commentWorker) {
    commentWorker.terminate();
  }

  return comments;
}
