import * as React from 'react';
import { Dropdown, DropdownItem, DropdownMenu } from 'semantic-ui-react';
import ThemeSelect from 'components/ThemeDiscovery/ThemeSelect';
import { SelectedTheme } from 'stores/ThemeDiscoveryStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HoverHint, ThemeCardInterface } from './ThemeTree';
import { ThemeNodeTypes } from './ThemeCard';
import './theme-card-dropdown.scss';

interface Props {
  groupId: string;
  id: string;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
  onOpen: () => void;
  parentId: string | undefined;
  setUpdateCandidate: (updateCandidate?: HoverHint) => void;
  trigger: React.ReactNode;
  updateTheme: (item: ThemeCardInterface) => void;
}

const ThemeCardDropdown = ({ updateTheme, groupId, id, onClose, onDelete, onOpen, open, parentId, setUpdateCandidate, trigger }: Props) => {
  const [showMergeMenu, setShowMergeMenu] = React.useState(false);
  const [showMoveMenu, setShowMoveMenu] = React.useState(false);
  const [updateItem, setUpdateItem] = React.useState<HoverHint | null>(null);

  const handleDropdownClose = () => {
    setUpdateCandidate(undefined);
  };

  const handleMergeTheme = (selectedTheme: SelectedTheme) => {
    const { subtheme, theme } = selectedTheme;
    const hintId = subtheme || theme;
    const parentHintId = subtheme ? theme : undefined;

    const hintItem = {
      id: hintId,
      parentId: parentHintId,
      merge: true,
      type: ThemeNodeTypes.THEME_CARD
    };

    setUpdateCandidate(hintItem);
    setUpdateItem(hintItem);
  };

  const handleMoveTheme = (selectedTheme: SelectedTheme) => {
    const { subtheme, theme } = selectedTheme;
    const hintId = subtheme || theme;

    const hintItem = {
      id: hintId,
      parentId: theme,
      type: ThemeNodeTypes.THEME_CARD
    };

    setUpdateCandidate(hintItem);
    setUpdateItem(hintItem);
  };

  React.useEffect(() => {
    if (updateItem) {
      updateTheme({ id, parentId });
      setUpdateItem(null);
    }
  }, [updateItem, updateTheme, id, parentId]);

  const handleMergeItemHover = () => {
    setShowMergeMenu(true);
  };

  const handleMergeItemLeave = () => {
    setShowMergeMenu(false);
  };

  const handleMoveItemHover = () => {
    setShowMoveMenu(true);
  };

  const handleMoveItemLeave = () => {
    setShowMoveMenu(false);
  };

  return (
    <Dropdown
      className={'theme-card-dropdown'}
      direction="left"
      icon={null}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      trigger={trigger}
    >
      <DropdownMenu>
        <div onMouseOver={handleMergeItemHover} onMouseLeave={handleMergeItemLeave}>
          <DropdownItem>
            <Dropdown
              icon={null}
              open={showMergeMenu}
              onClose={handleDropdownClose}
              trigger={
                <span className="theme-card-dropdown__item theme-card-dropdown__item--top-level">
                  <span className="theme-card-dropdown__icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>
                  Merge with
                </span>
              }
            >
              <DropdownMenu className="theme-card-dropdown__theme-select">
                <ThemeSelect
                  excludeId={id}
                  groupId={groupId}
                  hasOther={false}
                  isMerge={true}
                  onChange={handleMergeTheme}
                  isThemesDiscoveryItem={true}
                  themeSelectionDropdown={true}
                />
              </DropdownMenu>
            </Dropdown>
          </DropdownItem>
        </div>
        <div onMouseOver={handleMoveItemHover} onMouseLeave={handleMoveItemLeave}>
          <DropdownItem>
            <Dropdown
              icon={null}
              open={showMoveMenu}
              onClose={handleDropdownClose}
              trigger={
                <span className="theme-card-dropdown__item theme-card-dropdown__item--top-level">
                  <span className="theme-card-dropdown__icon">
                    <FontAwesomeIcon icon="chevron-left" />
                  </span>
                  Move to
                </span>
              }
            >
              <DropdownMenu className="theme-card-dropdown__theme-select">
                <ThemeSelect
                  excludeId={parentId}
                  groupId={groupId}
                  hasOther={false}
                  hideSubThemes={true}
                  isMerge={true}
                  isThemesDiscoveryItem={true}
                  onChange={handleMoveTheme}
                  themeSelectionDropdown={true}
                />
              </DropdownMenu>
            </Dropdown>
          </DropdownItem>
        </div>
        <DropdownItem as="button" onClick={onDelete}>
          <span className="theme-card-dropdown__item theme-card-dropdown__item--top-level">Delete</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export { ThemeCardDropdown };
