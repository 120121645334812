import * as React from 'react';
import classNames from 'classnames';
import { ButtonProps } from 'semantic-ui-react';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'toggle';
export type ButtonSubVariant = 'white' | 'neutral-text' | 'red';

interface Props extends ButtonProps {
  block?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  inline?: boolean;
  rounded?: boolean;
  size?: 'small' | 'medium';
  variant?: ButtonVariant;
  subvariant?: ButtonSubVariant;
  ariaLabel?: string;
}

const Button = React.forwardRef<HTMLButtonElement, Props>(({
  active,
  block,
  children,
  disabled,
  icon,
  inline,
  onClick,
  onKeyDown,
  subvariant,
  rounded = false,
  size = 'medium',
  variant = 'primary',
  ariaLabel = 'button',
  ...rest
}: Props, ref) => {
  return (
    <button
      className={classNames(
        `button-base button-base--${size} ${variant}-button ${variant}-button--${size}`, {
        [`${variant}-button--active`]: active,
        [`${variant}-button--${subvariant}`]: subvariant,
        ['button-base--block']: block,
        [`${variant}-button--disabled`]: disabled,
        [`${variant}-button--inline`]: inline,
        [`${variant}-button--rounded`]: rounded,
        [`${variant}-button--icon-only`]: icon && !children,
      })}
      disabled={disabled}
      onClick={(event) => onClick && onClick(event)}
      onKeyDown={(event) => onKeyDown && onKeyDown(event)}
      role="button"
      tabIndex={typeof rest.tabIndex === 'string' ? parseInt(rest.tabIndex, 10) : rest.tabIndex || 0}
      type="button"
      aria-label={ariaLabel}
      ref={ref}
    >
      {icon && <span className={`button-icon button-icon--${size}`}>{icon}</span>}
      {children}
    </button>
  );
});

export { Button };
