import * as React from 'react';
import { AnswersDataSet, AnswersFilters } from 'api/interfaces';
import { useSingleFilters } from '../hooks/useSingleFilters';
import './context-no-data.scss';

interface Props {
  answerFilters: AnswersFilters;
  answerDataSets: AnswersDataSet[];
}

export const ContextNoData = ({ answerDataSets, answerFilters }: Props) => {
  const translatedFilters = useSingleFilters(answerFilters, answerDataSets);

  return (
    <section className="context-no-data">
      <h3 className="context-no-data__title">Review used data</h3>
      {answerDataSets.length > 0 && (
        <>
          <div className="context-no-data-metadata">
            <h4 className="context-no-data-metadata__label">DATASETS</h4>
            <span className="context-no-data-metadata__datasets">
              {answerDataSets.map((dataSet) => dataSet.title).join(', ')}
            </span>
          </div>
          <div className="context-no-data-metadata">
            <h4 className="context-no-data-metadata__label">FILTERS</h4>
            <ul className="context-no-data-metadata__filters">
              {translatedFilters.map(({ filterName, filterValues }, index) => {
                return (
                  <li className="context-no-data-metadata__filter-item" key={`${filterName}-${index}`}>
                    <span className="context-no-data-metadata__filter-title">{filterName}</span> <span className="answer-metadata__filter-value">{filterValues}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </section>
  );
};
