import { render, staticRenderFns } from "./ImpactComponent.vue?vue&type=template&id=681d08b8&scoped=true"
import script from "./ImpactComponent.vue?vue&type=script&lang=js"
export * from "./ImpactComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "681d08b8",
  null
  
)

export default component.exports