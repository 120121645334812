import { findMergeRoot, getGroupKey, ThemesJson } from 'lib/theme-file-parser';
import {
  findKey,
  forEach,
  includes,
  isArray,
  keys,
  pickBy,
  values,
  without
} from 'lodash';

export enum TransformType {
  AddPhrases = 'AddPhrases',
  AddTheme = 'AddTheme',
  ChangeGroupTitle = 'ChangeGroupTitle',
  ChangeThemeTitle = 'ChangeThemeTitle',
  DeleteThemes = 'DeleteThemes',
  DeletePhrases = 'DeletePhrases',
  MergeTheme = 'MergeTheme',
  MoveTheme = 'MoveTheme',
  UnmergeTheme = 'UnmergeTheme',
  MarkAllAsReviewed = 'MarkAllAsReviewed'
}

export type Transform =
  | AddPhrasesTransform
  | DeletePhrasesTransform
  | ChangeGroupTitleTransform
  | ChangeThemeTitleTransform
  | AddThemeTransform
  | DeleteThemesTransform
  | MergeThemeTransform
  | MoveThemeTransform
  | UnmergeThemeTransform
  | MarkAllAsReviewedTransform;

interface BaseTransform {
  groupId: string;
  message: {
    prefix: string;
    theme?: string;
  };
  persisted?: boolean;
}

export interface AddPhrasesTransform extends BaseTransform {
  phrases: string[];
  toThemeId: string;
  fromThemeId?: string;
  type: TransformType.AddPhrases;
}

export interface DeletePhrasesTransform extends BaseTransform {
  phrases: string[];
  themeId: string;
  type: TransformType.DeletePhrases;
}

export interface ChangeGroupTitleTransform extends BaseTransform {
  fromTitle?: string;
  toTitle: string;
  type: TransformType.ChangeGroupTitle;
}

export interface ChangeThemeTitleTransform extends BaseTransform {
  fromTitle?: string;
  themeId: string;
  toTitle: string;
  type: TransformType.ChangeThemeTitle;
}

export interface AddThemeTransform extends BaseTransform {
  parentThemeId?: string;
  themeId: string;
  title: string;
  type: TransformType.AddTheme;
  newMappedPhrases?: string[];
  themeIsNew?: boolean;
  mappedPhrasesPreviousEntries?: { [key: string]: string };
}

export interface DeleteThemesTransform extends BaseTransform {
  themeIds: string[];
  previousState: { [id: string]: { merged?: string; sub_themes?: string[], parentId?: string } };
  type: TransformType.DeleteThemes;
}

export interface UnmergeThemeTransform extends BaseTransform {
  mergeId: string;
  sourceId: string;
  type: TransformType.UnmergeTheme;
}

export interface MergeThemeTransform extends BaseTransform {
  mergeId: string;
  targetId: string;
  previousParent: {
    id: string; // either itself (if theme) or parent theme if subtheme
    subthemes: string[];
  };
  previousMerges: {
    [id: string]: string | undefined;
  };
  type: TransformType.MergeTheme;
}

export interface MoveThemeTransform extends BaseTransform {
  moveId: string;
  targetId: string | undefined;
  parentOrChildren: string | string[];
  type: TransformType.MoveTheme;
}

export interface MarkAllAsReviewedTransform extends BaseTransform {
  type: TransformType.MarkAllAsReviewed;
  themesUnmarkedFromNew: undefined | any;
  themesUnmarkedFromReview: undefined | any;
}

export function addPhrase(themeFile: ThemesJson, action: AddPhrasesTransform) {
  const { groupId, phrases, toThemeId } = action;
  const group = themeFile[groupId];

  const root = findMergeRoot(themeFile, groupId, toThemeId);

  if (!root || !group) {
    return themeFile;
  }

  const { themeId, entry } = root;

  if (!entry.deleted) {
    forEach(phrases, phrase => {
      themeFile.map[phrase] = themeId;
    });
  }

  return themeFile;
}

export function reverseAddPhrase(
  themeFile: ThemesJson,
  action: AddPhrasesTransform
) {
  const { groupId, phrases, fromThemeId, toThemeId } = action;
  const group = themeFile[groupId];
  if (group && group[toThemeId]) {
    forEach(phrases, phrase => {
      // if fromThemeId is undefined, means it didn't exist beforehand
      if (fromThemeId === undefined) {
        delete themeFile.map[phrase];
      } else {
        themeFile.map[phrase] = fromThemeId;
      }
    });
  }
  return themeFile;
}

export function changeGroupTitle(
  themeFile: ThemesJson,
  action: ChangeGroupTitleTransform
) {
  const { groupId, toTitle } = action;
  const group = themeFile[groupId];
  if (group) {
    // initialise column grouping names
    const jsonKey = 'column_grouping_names';
    const groupNames = (themeFile[jsonKey] = themeFile[jsonKey] || {});
    const nameKey = getGroupKey(groupId) || groupId;
    groupNames[nameKey] = toTitle;
  }
  return themeFile;
}

export function reverseChangeGroupTitle(
  themeFile: ThemesJson,
  action: ChangeGroupTitleTransform
) {
  const { groupId, fromTitle } = action;
  const group = themeFile[groupId];
  if (group) {
    // initialise column grouping names
    const jsonKey = 'column_grouping_names';
    const groupNames = (themeFile[jsonKey] = themeFile[jsonKey] || {});
    const nameKey = getGroupKey(groupId) || groupId;
    if (fromTitle === undefined) {
      delete groupNames[nameKey];
    } else {
      groupNames[nameKey] = fromTitle;
    }
  }
  return themeFile;
}

export function changeThemeTitle(
  themeFile: ThemesJson,
  action: ChangeThemeTitleTransform
) {
  const { groupId, themeId, toTitle } = action;
  const group = themeFile[groupId];
  if (group) {
    themeFile.titles[themeId] = toTitle;
  }
  return themeFile;
}

export function reverseChangeThemeTitle(
  themeFile: ThemesJson,
  action: ChangeThemeTitleTransform
) {
  const { groupId, themeId, fromTitle } = action;
  const group = themeFile[groupId];
  if (group && fromTitle !== undefined) {
    themeFile.titles[themeId] = fromTitle;
  }
  return themeFile;
}

export function addTheme(themeFile: ThemesJson, action: AddThemeTransform) {
  const { groupId, parentThemeId, themeId, title } = action;
  const group = themeFile[groupId];

  if (!group) {
    return themeFile;
  }
  const hasParent = parentThemeId && group[parentThemeId] && group[parentThemeId].sub_themes;

  if (!hasParent && parentThemeId) {
    // if it doesn't have a parent but we say it SHOULD have one, bail
    return themeFile;
  }

  // insert into the group list
  themeFile.titles[themeId] = title;
  group[themeId] = { freq: 0 };

  if (action.themeIsNew) {
    group[themeId].new = true;
  }

  if (hasParent) {
    // if it has a parent, tell it about this new subtheme
    group[parentThemeId].sub_themes.push(themeId);
  } else {
    //  if no parent, it gets its own sub_themes array
    group[themeId].sub_themes = [];
  }

  // now add any mapped phrases provided
  if (action.newMappedPhrases) {
    // make sure to record any mapped phrases now being overriden and add in the new phrases
    const previousMappedPhraseTargets = {};
    forEach(action.newMappedPhrases, phrase => {
      if (themeFile.map[phrase]) {
        previousMappedPhraseTargets[phrase] = themeFile.map[phrase];
      }
      themeFile.map[phrase] = themeId;
    });
    action.mappedPhrasesPreviousEntries = previousMappedPhraseTargets;
  }
  return themeFile;
}

export function reverseAddTheme(
  themeFile: ThemesJson,
  action: AddThemeTransform
) {
  const { groupId, parentThemeId, themeId } = action;
  const group = themeFile[groupId];

  if (!group) {
    return themeFile;
  }
  const hasParent = parentThemeId && group[parentThemeId] && group[parentThemeId].sub_themes;

  if (!hasParent && parentThemeId) {
    // if it doesn't have a parent but we say it SHOULD have one, bail
    return themeFile;
  }

  // delete references
  delete themeFile.titles[themeId];
  delete group[themeId];

  // remove any added phrases
  if (action.newMappedPhrases) {
    forEach(action.newMappedPhrases, phrase => {
      delete themeFile.map[phrase];
    });
  }

  // add back in any mapped phrases that were overriden
  if (action.mappedPhrasesPreviousEntries) {
    forEach(action.mappedPhrasesPreviousEntries, (phrase, targetThemeId) => {
      themeFile.map[targetThemeId] = phrase;
    });
  }

  // if it has a parent, tell it about this subtheme disappearing
  if (hasParent) {
    const parent = group[parentThemeId];
    parent.sub_themes = without(parent.sub_themes, themeId);
  }
  return themeFile;
}

export function collectIds(group: object, ids: string[]) {
  const result = [] as string[];
  forEach(ids, id => {
    if (group[id]) {
      result.push(id);

      // add all of the merged items we find
      const mergedThemes = pickBy(group, val => val['merged'] === id);
      result.push(...collectIds(group, keys(mergedThemes)));

      // add all of the subthemes we find
      const subthemes = group[id].sub_themes;
      if (subthemes) {
        result.push(...collectIds(group, subthemes));
      }
    }
  });

  return result;
}

export function deleteThemes(
  themeFile: ThemesJson,
  action: DeleteThemesTransform
) {
  const { groupId, themeIds } = action;
  const group = themeFile[groupId];

  if (group) {
    const ids = collectIds(group, themeIds);
    forEach(ids, id => {
      if (group[id]) {
        group[id].deleted = true;
        delete group[id].sub_themes;
        delete group[id].merged;

        const parentId = findParentId(group, id);
        if (parentId && group[parentId]) {
          const parent = group[parentId];
          parent.sub_themes = without(parent.sub_themes, id);
        }
      }
    });
  }
  return themeFile;
}

export function reverseDeleteThemes(
  themeFile: ThemesJson,
  action: DeleteThemesTransform
) {
  const { groupId, previousState } = action;
  const group = themeFile[groupId];

  if (group) {
    forEach(previousState, (state, id) => {
      if (group[id]) {
        delete group[id].deleted;
        // determine if there needs to be an entry put back in basetheme
        const parentId = state.parentId;
        if (parentId) {
          const parent = group[parentId];
          parent.sub_themes.push(id);
        }
        delete state.parentId;
        // assign the pre-change state
        Object.assign(group[id], state);
      }
    });
  }
  return themeFile;
}

export function mergeThemes(
  themeFile: ThemesJson,
  action: MergeThemeTransform
) {
  const { groupId, mergeId, previousParent, targetId } = action;
  const group = themeFile[groupId];

  if (group && group[mergeId]) {
    const ids = collectIds(group, [mergeId]);

    // assign all the `merged` properties
    forEach(ids, id => {
      if (group[id]) {
        group[id].merged = targetId;
      }
    });
    const theme = group[mergeId];
    if (theme.sub_themes) {
      delete theme.sub_themes;
    } else {
      const { id: previousParentId } = previousParent;
      const parentSubthemes = group[previousParentId].sub_themes;
      group[previousParentId].sub_themes = without(parentSubthemes, mergeId);
    }
  }

  return themeFile;
}

export function reverseMergeThemes(
  themeFile: ThemesJson,
  action: MergeThemeTransform
) {
  const { groupId, previousMerges: mergeState, previousParent } = action;
  const group = themeFile[groupId];

  if (group) {
    forEach(mergeState, (val, key) => {
      if (val === undefined) {
        delete group[key].merged;
      } else {
        group[key].merged = val;
      }
    });

    const { id, subthemes } = previousParent;
    const previousParentTheme = group[id];
    previousParentTheme.sub_themes = subthemes;
  }
  return themeFile;
}

export function deletePhrases(
  themeFile: ThemesJson,
  action: DeletePhrasesTransform
) {
  const { groupId, phrases, themeId } = action;
  const group = themeFile[groupId];

  if (group && group[themeId]) {
    forEach(phrases, phrase => {
      const mappedId = themeFile.map[phrase];
      const mergeId = group[mappedId]?.merged ?? null;

      if (mappedId === themeId || mergeId === themeId) {
        delete themeFile.map[phrase];
      }
    });
  }
  return themeFile;
}

export function reverseDeletePhrases(
  themeFile: ThemesJson,
  action: DeletePhrasesTransform
) {
  const { groupId, phrases, themeId } = action;
  const group = themeFile[groupId];

  if (group && group[themeId]) {
    forEach(phrases, phrase => {
      themeFile.map[phrase] = themeId;
    });
  }
  return themeFile;
}

export function moveTheme(themeFile: ThemesJson, action: MoveThemeTransform) {
  const { groupId, targetId, moveId, parentOrChildren } = action;

  const group = themeFile[groupId];
  if (group && group[moveId]) {
    const moving = group[moveId];
    if (targetId === undefined) {
      moving.sub_themes = moving.sub_themes || [];
      if (!isArray(parentOrChildren) && group[parentOrChildren]) {
        // had parent, remove reference from there
        const previous = group[parentOrChildren];
        previous.sub_themes = without(previous.sub_themes, moveId);
      }
    } else {
      const target = group[targetId];
      if (!target || !target.sub_themes) {
        // bad target, eject
        return themeFile;
      } else if (parentOrChildren !== targetId) {
        // if target is same as where it's come from, we're done
        if (isArray(parentOrChildren)) {
          // had children, also add them & clear from old location
          target.sub_themes.push(...parentOrChildren);
          delete moving.sub_themes;
        } else if (group[parentOrChildren]) {
          // had parent, remove reference from there
          const previous = group[parentOrChildren];
          previous.sub_themes = without(previous.sub_themes, moveId);
        }
        // add the theme we're moving
        target.sub_themes.push(moveId);
      }
    }
  }

  return themeFile;
}

export function reverseMoveTheme(
  themeFile: ThemesJson,
  action: MoveThemeTransform
) {
  const { groupId, targetId, moveId, parentOrChildren } = action;

  const group = themeFile[groupId];
  if (group && group[moveId]) {
    const moving = group[moveId];

    if (isArray(parentOrChildren)) {
      moving.sub_themes = parentOrChildren;
      if (targetId && group[targetId] && group[targetId]) {
        const target = group[targetId];
        target.sub_themes = without(
          target.sub_themes,
          moveId,
          ...parentOrChildren
        );
      }
    } else if (targetId !== parentOrChildren) {
      const parent = group[parentOrChildren];
      if (parent) {
        parent.sub_themes.push(moveId);
      }
      delete moving.sub_themes;

      if (targetId && group[targetId]) {
        const previous = group[targetId];
        previous.sub_themes = without(previous.sub_themes, moveId);
      }
    }
  }

  return themeFile;
}

function findParentId(group: object, id: string) {
  return findKey(group, (o: object) => {
    return includes(o['sub_themes'], id);
  });
}

export function unmergeTheme(
  themeFile: ThemesJson,
  action: UnmergeThemeTransform
) {
  const { groupId, mergeId, sourceId } = action;
  const group = themeFile[groupId];
  if (!group || !group[sourceId] || !group[mergeId]) {
    return themeFile;
  }
  const unmerging = group[mergeId];
  delete unmerging.merged;
  const sourceParentId = findParentId(group, sourceId);
  if (sourceParentId) {
    if (group[sourceParentId]) {
      group[sourceParentId].sub_themes.push(mergeId);
    }
  } else {
    unmerging.sub_themes = [];
  }
  return themeFile;
}

export function reverseUnmergeTheme(
  themeFile: ThemesJson,
  action: UnmergeThemeTransform
) {
  const { groupId, mergeId, sourceId } = action;
  const group = themeFile[groupId];
  if (!group || !group[sourceId] || !group[mergeId]) {
    return themeFile;
  }
  const remerging = group[mergeId];
  delete remerging.sub_themes;
  remerging.merged = sourceId;
  const sourceParentId = findParentId(group, sourceId);
  if (sourceParentId) {
    const source = group[sourceParentId];
    if (source) {
      source.sub_themes = without(source.sub_themes, mergeId);
    }
  }
  return themeFile;
}

export function markAllAsReviewed(
  themeFile: ThemesJson,
  action: MarkAllAsReviewedTransform
) {
  // Removes the 'new' marker from all themes after keeping a reference to which ones had it

  const groupings = themeFile.column_groupings;
  let groupingsChangedNew = {};
  let groupingsChangedReview = {};
  if (groupings) {
    const groups = values(groupings);
    forEach(groups, num => {
      const key = `themes${ num }`;
      groupingsChangedNew[key] = [];
      groupingsChangedReview[key] = [];
      forEach(themeFile[key], (theme, themekey) => {
        if (theme.new) {
          groupingsChangedNew[key].push(themekey);
          delete theme.new;
        }
        if (theme.review) {
          groupingsChangedReview[key].push(themekey);
          delete theme.review;
        }
      });
    });
  }

  // we store the unmarked themes so we can mark them again
  action.themesUnmarkedFromNew = groupingsChangedNew;
  action.themesUnmarkedFromReview = groupingsChangedReview;

  return themeFile;
}

export function reverseMarkAllAsReviewed(
  themeFile: ThemesJson,
  action: MarkAllAsReviewedTransform
) {
  // Puts the 'new' marker back on any themes listed on the transform
  if (action.themesUnmarkedFromNew) {
    forEach(action.themesUnmarkedFromNew, (themeList, groupingId) => {
      forEach(themeList, theme => {
        themeFile[groupingId][theme].new = true;
      });
    });
  }
  // Puts the 'review' marker back on any themes listed on the transform
  if (action.themesUnmarkedFromReview) {
    forEach(action.themesUnmarkedFromReview, (themeList, groupingId) => {
      forEach(themeList, theme => {
        themeFile[groupingId][theme].review = true;
      });
    });
  }

  return themeFile;
}

export function apply(themeFile: ThemesJson, transform: Transform) {
  switch (transform.type) {
    case TransformType.AddTheme:
      return addTheme(themeFile, transform);
    case TransformType.AddPhrases:
      return addPhrase(themeFile, transform);
    case TransformType.DeletePhrases:
      return deletePhrases(themeFile, transform);
    case TransformType.ChangeGroupTitle:
      return changeGroupTitle(themeFile, transform);
    case TransformType.ChangeThemeTitle:
      return changeThemeTitle(themeFile, transform);
    case TransformType.DeleteThemes:
      return deleteThemes(themeFile, transform);
    case TransformType.MergeTheme:
      return mergeThemes(themeFile, transform);
    case TransformType.MoveTheme:
      return moveTheme(themeFile, transform);
    case TransformType.UnmergeTheme:
      return unmergeTheme(themeFile, transform);
    case TransformType.MarkAllAsReviewed:
      return markAllAsReviewed(themeFile, transform);
    default:
      return themeFile;
  }
}

export function undo(themeFile: ThemesJson, transform: Transform) {
  switch (transform.type) {
    case TransformType.AddTheme:
      return reverseAddTheme(themeFile, transform);
    case TransformType.AddPhrases:
      return reverseAddPhrase(themeFile, transform);
    case TransformType.DeletePhrases:
      return reverseDeletePhrases(themeFile, transform);
    case TransformType.ChangeGroupTitle:
      return reverseChangeGroupTitle(themeFile, transform);
    case TransformType.ChangeThemeTitle:
      return reverseChangeThemeTitle(themeFile, transform);
    case TransformType.DeleteThemes:
      return reverseDeleteThemes(themeFile, transform);
    case TransformType.MergeTheme:
      return reverseMergeThemes(themeFile, transform);
    case TransformType.MoveTheme:
      return reverseMoveTheme(themeFile, transform);
    case TransformType.UnmergeTheme:
      return reverseUnmergeTheme(themeFile, transform);
    case TransformType.MarkAllAsReviewed:
      return reverseMarkAllAsReviewed(themeFile, transform);
    default:
      return themeFile;
  }
}
