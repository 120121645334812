import {
  AnswerResponse,
  AnswersAction,
  AnswersActionType,
  TableAnswerPartType,
  TextAnswerPartType,
  TitleAnswerPartType,
  VisualizationAnswerPartType,
  isComparisonResponse,
  isNonComparisonResponse,
} from 'api/interfaces';
import { CopyContentToClipboard } from 'components/CopyToClipboard/CopyContentToClipboard';
import FeatureFeedbackByThumbs from 'components/FeatureFeedback/FeatureFeedbackByThumbs';
import { FeatureFeedbackTextConfig } from 'components/FeatureFeedback/types';
import analytics from 'lib/analytics';
import { observer } from 'mobx-react';
import * as React from 'react';
import { AnswerOutput, isAnswerResponse } from 'types/custom';
import { AnswerHeader } from './AnswerHeader';
import { AnswerToolbar, ToolbarItem } from './AnswerToolbar';
import { Context } from './Context';
import { RefreshAnswer } from './RefreshAnswer';
import { TableAnswerPart } from './TableAnswerPart';
import { TextAnswerPart } from './TextAnswerPart';
import { TitleAnswerPart } from './TitleAnswerPart';
import { VisualizationAnswerPart } from './VisualizationAnswerPart';
import { Warning } from './Warning';
import WarningBarchartIcon from 'images/icons/warning-barchart.svg';

const feedbackText: FeatureFeedbackTextConfig = {
  positivePopupMessage: 'This is a good answer',
  negativePopupMessage: 'This is a poor answer',
  thumbsUp: {
    title: 'Provide additional feedback',
    subTitle: 'What did you like about the response',
    textAreaPlaceholder:
      'Please provide as much details as you can, including what elements you liked from your answer',
  },
  thumbsDown: {
    title: 'Provide additional feedback',
    subTitle: 'What were the issues with the response? How could we improve it',
    textAreaPlaceholder:
      'Please provide as much detail as you can, including what elements you\'d like to see as part of your answer',
  },
};

export type Props = {
  item: AnswerOutput,
  savedAnswerId: string,
  hasVisualization: boolean,
  hasSuggestions: boolean,
  hasQuotes: boolean,
  isReadOnly: boolean,
  onAction: (action: AnswersAction, type?: string) => void,
  showRefreshTooltip: boolean,
};

export const Answer = observer(function (props: Props) {
  const answerInfo = props.item.content;
  const dataSets = props.item.dataSets;

  const focusAnswerElement = () => {
    const answerElement = document.getElementById(`answer-${ props.item.id }`);

    if (answerElement instanceof HTMLElement) {
      answerElement.focus();
    }
  };

  const refreshAnswer = () => {
    if (!isAnswerResponse(answerInfo)) {
      return;
    }

    const action: AnswersAction = {
      action: AnswersActionType.refreshAnswer,
      question: answerInfo.question,
      collectionId: props.savedAnswerId,
    }

    props.onAction(action, 'refresh-answer');
  }

  function getActionToolbar(answer: AnswerResponse) {

    if (!isAnswerResponse(answerInfo)) {
      return null;
    }
    let answerContents = '';
    if (answer.type === 'table') {
      answerContents = answer.contents.table.columns.map((col) => col.name).join(', ');
    } else if (answer.type === 'text') {
      answerContents = answer.contents;
    } else if (answer.type === 'multipart') {
      answerContents = answer.contents.map((content) => {
        if (content.type === 'text') {
          return content.contents;
        } else if (content.type === 'table') {
          return JSON.stringify(content.table);
        }
        return '';
      }).join('\n');
    }

    // determine which actions have been 'supported' by the answer
    // if none have been defined, then we use the original defaults of 'addVisualization' and 'suggestSolutions'
    // if an empty list has been defined then it is none
    let supportedActions = (answerInfo.supportedActions === undefined ?
      ['addVisualization', 'suggestSolutions'] :
      [...answerInfo.supportedActions]);
    // selecting quotes however is an _implicit_ action determined by whether there is input data to show
    // Note: because this does not take into account 'comparisons' it will not show for comparison questions
    const hasComparisons = isComparisonResponse(answerInfo) && (answerInfo.comparisons.some((c) => c.inputData?.length > 0));
    const hasInputData = isNonComparisonResponse(answerInfo) && (answerInfo.inputData || []).length > 0;
    if (hasInputData || hasComparisons) {
      supportedActions.push('selectQuotes');
    }

    let items: ToolbarItem[] = [];
    // The ordering of actions is set by the frontend (here)
    if (supportedActions.includes('addVisualization')) {
      let dataType = 'volume';
      if (answer.questionType === 'impact') {
        dataType = 'impact';
      } else if (answer.questionType === 'scorechange') {
        dataType = 'waterfall';
      }
      items.push({
        label: 'Add a visualization',
        icon: 'chart-bar',
        onClick: () => {
          analytics.track('Answers: Answers Item', { 'Type': 'Chart' });

          const comparisons = isComparisonResponse(answer) ? answer.comparisons : undefined;

          props.onAction({
            action: AnswersActionType.addVisualization,
            context: answerContents,
            question: answer.question,
            dataType: dataType,
            score: answer.score,
            filters: answer.filters,
            comparisons,
          });
        },
        isActive: props.hasVisualization
      });
    }
    if (supportedActions.includes('selectQuotes')) {
      items.push({
        label: 'Show examples',
        icon: 'comment',
        onClick: () => {
          analytics.track('Answers: Answers Item', { 'Type': 'Quote' });
          props.onAction({
            action: AnswersActionType.selectQuotes,
            context: answerContents,
            question: answer.question,
            filters: answer.filters,
          });
        },
        isActive: props.hasQuotes
      });
    }
    if (supportedActions.includes('suggestSolutions')) {
      items.push({
        label: 'Create suggestions',
        icon: 'lightbulb',
        onClick: () => {
          analytics.track('Answers: Answers Item', { 'Type': 'Suggestions' });
          props.onAction({
            action: AnswersActionType.suggestSolutions,
            context: answerContents,
            question: answer.question,
            filters: answer.filters,
          });
        },
        isActive: props.hasSuggestions
      });
    }

    return (<AnswerToolbar items={items} />);
  }

  function getContent() {
    let parts: (TitleAnswerPartType | TextAnswerPartType | TableAnswerPartType | VisualizationAnswerPartType)[] = [];
    if (answerInfo.type === 'multipart') {
      parts = answerInfo.contents;
    } else if (answerInfo.type === 'text') {
      parts = [answerInfo];
    } else if (answerInfo.type === 'table') {
      parts = [answerInfo.contents];
    } else {
      return null;
    }
    const renderedParts = parts.map((part, index) => {
      switch (part.type) {
        case 'text': {
          return (
            <TextAnswerPart
              key={index}
              savedAnswerId={props.savedAnswerId}
              answerInfo={answerInfo}
              textData={part}
              isReadOnly={props.isReadOnly}
              onThemeClick={(action) => {
                analytics.track('Answers: Answers Item', { 'Type': 'Theme' });
                props.onAction(action);
              }}
              onTextSelection={() => {
                analytics.track('Answers: Select Answer', { 'Type': 'Summary' });
              }}
              onCopyClick={() => {
                analytics.track('Answers: Copy Answer', { 'Type': 'Summary' });
              }}
            />
          );
        }
        case 'title': {
          return (
            <TitleAnswerPart
              key={index}
              data={part}
            />
          );
        }
        case 'table': {
          return (
            <TableAnswerPart
              key={index}
              data={part}
            />
          );
        }
        case 'visualization': {
          return (
            <VisualizationAnswerPart
              key={index}
              content={part.contents}
              showTitle={true}
            />
          );
        }
        default: return null;
      }
    });

    const warnings = answerInfo.warnings;

    const getCopyContent = () => document.getElementById(`answer-content-${ props.item.id }`);

    return (
      <div>
        {warnings && warnings.map((warning, index) => {
          return (
              <Warning
                title={warning.contents}
                key={`answer-warning-${index}`}
                icon={<WarningBarchartIcon />}
              >
                <p className="answers-warning__description answers-warning--no-space-before-description">
                  , but you can check the individual answers from the datasets where there is available data.
                </p>
              </Warning>
            )
          })
        }
        <div id={`answer-content-${ props.item.id }`}>
          {renderedParts}
        </div>
        <div className="answers__copy-content">
          <CopyContentToClipboard
            getContentElement={() => getCopyContent()}
            popupText="Copy answer to clipboard"
          />
          <span className="answers__feature-feedback">
            <FeatureFeedbackByThumbs
              onFeedbackModalUnmount={() => focusAnswerElement()}
              featureId="answers"
              askForDescription={true}
              data={answerInfo}
              textConfig={feedbackText}
              tooltipPosition="bottom left"
            />
          </span>
          {props.showRefreshTooltip && (
            <RefreshAnswer
              isReadOnly={props.isReadOnly}
              onRefreshAnswer={refreshAnswer}
              showRefreshTooltip={props.showRefreshTooltip}
              utcDate={props.item.createdAt}
            />
          )}
        </div>
      </div>
    );
  }

  if (!isAnswerResponse(answerInfo)) {
    return null;
  }

  return (
    <section>
      <AnswerHeader answer={answerInfo} />
      {getContent()}
      <Context
        answer={answerInfo}
        dataSets={dataSets}
      />
      {!props.isReadOnly && getActionToolbar(answerInfo)}
    </section>
  );

});
