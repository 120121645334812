import analytics from 'lib/analytics';
import { toRoute } from 'lib/route-helper';
import { stringify } from 'query-string';
import { getActiveDashboardUIStore, getOrganizationStore } from 'stores/RootStore';
import {
  canNavigate,
  matchUrl
} from 'vue/dashboards/Dashboard/Widgets/Utils/navigate-params';

// hasCommentsSlot refers to whether there is an intermediary step before navigation of showing some commments
export default function (tool, subtool) {
  return {
    props: {
      isWeighted: { default: false, type: Boolean },
      source: { type: String }
    },
    data() {
      return {
        organizationStore: getOrganizationStore(),
        activeDashboardUIStore: getActiveDashboardUIStore(),
        selectedTheme: null,
        isCommentsEnabled: false,
        isSummaryEnabled: false
      };
    },
    computed: {
      canClickTheme() {
        return this.canNavigate || this.isCommentsEnabled || this.isSummaryEnabled;
      },
      canNavigate() {
        return !this.isWeighted && typeof this.source === 'string' && canNavigate(this.source);
      },
      path() {
        const { source } = this;
        const { orgId } = this.organizationStore;
        const { viewID, visID, surveyID } = matchUrl(source);
        return `${toRoute(orgId, surveyID, viewID, visID)}/${tool}`;
      }
    },
    methods: {
      // isFinalNavigate indicates that there shouldn't be any check for other logic before navigating
      // i.e. if the user is navigating from within comments then they should definitely navigate
      doNavigate(theme, subtheme, options, isFinalNavigate) {
        const { $route, canNavigate, path, isCommentsEnabled, isSummaryEnabled } = this;
        // if the navigation is to a specific theme, and there is a comments component, don't navigate yet
        if ((isCommentsEnabled || isSummaryEnabled) && !isFinalNavigate && theme) {
          this.selectedTheme = { theme, subtheme, options };
          analytics.track('Report: open comments', { category: 'Report' });
          return;
        }
        if (!canNavigate) {
          return;
        }
        const { compare, filters } = $route.query;
        const query = {
          ...options,
          compare,
          expand: !!theme,
          filters,
          subtheme: subtheme ? subtheme.title : undefined,
          subtool,
          theme: theme ? theme.title : undefined
        };
        analytics.track('Report: to Analysis link', { category: 'Report' });
        window.location = `/#${path}?${stringify(query)}`;
      }
    }
  };
}
