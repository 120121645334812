<template>
  <div class="date-filter-container">
    <div class="date-filter">
      <div
        v-if="activeDashboardUIStore.isEditing"
        class="filter-header"
      >
        <span>Date</span>
      </div>
      <div
        v-else
        class="header"
      >
        Date
      </div>
      <el-alert
        v-if="requestDateError"
        ref="date-range-error"
        :title="`No 'date_range' provided in config for 'date-filter'.`"
        type="error"
        show-icon
      />
      <el-button
        v-else
        size="medium"
        class="date-selector-btn"
        @click="openFilter"
      >
        <font-awesome-icon icon="calendar" /> {{ dateFilterName }}
      </el-button>
      <div class="date-subtext hide">
        {{ subText }}
      </div>
      <dashboard-date-filter
        ref="dateFilterWidget"
        :filter-data="dateFilterData"
        :selected-filters="selectedFilters"
        class="date-filter-smart-label"
        filter-key="baseline"
        @selectionChanged="selectionChanged"
      />
      <configure-date-filter
        v-if="isConfigureDateModalVisible"
        :date-picker-config="dateConfig"
        @onClose="hideConfigureDateModal"
      />
    </div>
    <settings-menu
      v-if="activeDashboardUIStore.isEditing"
      :show-edit="true"
      :show-delete="true"
      @onEdit="showConfigureDateModal"
      @onDelete="deleteDateFilter"
    />
  </div>
</template>

<script>
import _, { compact, flatten, isEmpty } from 'lodash';
import DashboardFilterDate from 'vue/components/Filters/FilterDatePicker.vue';
import SettingsMenu from 'vue/dashboards/DashboardEditable/SettingsMenu.vue';
import ConfigureDateFilter from 'vue/dashboards/DashboardEditable/ConfigureDateFilter.vue';
import thematicData from 'vue/libs/thematicData';
import { dateStringToUtc, utcToDateString, previousDateRangeFromCurrentDateRange } from 'lib/date';
import queryBuilder from 'vue/libs/queryBuilder';
import analytics from 'lib/analytics';
import { getActiveDashboardUIStore } from 'stores/RootStore';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

export default {
  name: 'DateFilter',
  components: {
    'dashboard-date-filter': DashboardFilterDate,
    SettingsMenu,
    ConfigureDateFilter,
  },
  props: {
    dateConfig: {
      type: Object,
    },
    sources: {
      type: Array,
    },
    dashboardId: {
      type: String,
    }
  },
  data() {
    return {
      activeDashboardUIStore: getActiveDashboardUIStore(),

      selectedFilters: null,
      dateStart: '2018-01-01',
      dateEnd: '2018-01-01',
      dateId: 'date',
      dateFilterName: 'Loading',
      subText: '',
      hasLoaded: false,
      requestDateError: false,
      isConfigureDateModalVisible: false,
      datePickerTarget: 'datePicker',
    };
  },
  computed: {
    dateFilterData() {
      const filterData = {
        name: '',
        date_end: this.dateEnd,
        date_start: this.dateStart,
        column: _.get(this, 'dateConfig.column', null),
        resolution: _.get(this, 'dateConfig.resolution', null),
        id: this.dateId,
      };
      return filterData;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    openFilter: function () {
      this.$refs.dateFilterWidget.open();
    },
    selectionChanged: function (selection) {
      this.dateFilter = selection;
      this.dateFilterName = _.get(selection, 'selected.0.name', '');
      // calculate the previous time period
      const startDate = dateStringToUtc(_.get(selection, 'selected.0.startDate', '2018-01-01'));
      const endDate = dateStringToUtc(_.get(selection, 'selected.0.endDate', '2018-01-01'));
      this.changeSubText(startDate, endDate);

      const previousDateRange = previousDateRangeFromCurrentDateRange(startDate, endDate);

      const rql = queryBuilder.buildDateQuery(selection.filterId, previousDateRange.start, previousDateRange.end);
      const dateInfo = {
        selected: {
          name: _.get(selection, 'selected.0.name', ''),
          rql: _.get(selection, 'selected.0.rql', ''),
          startDate,
          endDate,
        },
        previous: {
          name: 'Previous Period',
          startDate: previousDateRange.start,
          endDate: previousDateRange.end,
          rql,
        },
        validRange: {
          start: this.dateStart,
          end: this.dateEnd,
        },
        column: selection.column,
        id: selection.filterId,
      };
      if (this.hasLoaded) {
        this.$emit('selectionChanged', dateInfo);
      }

      analytics.track('Dashboard: Date Selection', {
        category: 'Dashboard',
        dateInfo,
      });
    },
    dateToString: function (date) {
      return moment(date).format('D MMM YYYY');
    },
    changeSubText(startDate, endDate) {
      this.subText = this.dateToString(new Date(startDate)) + ' to ' + this.dateToString(new Date(endDate));
    },

    loadData: async function () {
      // Get date info from all sources to make up the date range
      const configs = await Promise.all(
        this.sources.map((dataSource) => {
          return thematicData.getConfig({ dataSource }, this.dashboardId);
        }),
      );

      const dateRanges = compact(compact(configs).map((c) => c.date_range));
      let startDates = dateRanges.map((d) => moment(d[0])); // First element is start date
      let endDates = dateRanges.map((d) => moment(d[1])); // Second element is end date

      this.hasLoaded = true;
      if (isEmpty(dateRanges)) {
        this.requestDateError = true;
        return;
      }

      if (isEmpty(startDates) && isEmpty(endDates)) {
        //Absolute fallback, take a guess at a valid range
        const today = utcToDateString(new Date());
        const lastYear = utcToDateString(Moment().subtract(1, 'year').toDate());
        this.dateStart = lastYear;
        this.dateEnd = today;
      } else {
        this.dateStart = moment.min(startDates).format('YYYY-MM-DD'); // Start date to be the min of all start dates
        this.dateEnd = moment.max(endDates).format('YYYY-MM-DD'); // End date to be the max of all end dates
      }

      this.changeSubText(this.dateStart, this.dateEnd);

      // try to find the relevant id
      const filters = flatten(configs.map((c) => c.filters));
      for (let i = 0; i < filters.length; i++) {
        if (filters[i].type === 'date' && filters[i].column === this.dateConfig.column) {
          this.dateId = filters[i].id;
          break;
        }
      }
    },
    showConfigureDateModal() {
      this.isConfigureDateModalVisible = true;
    },
    hideConfigureDateModal() {
      this.isConfigureDateModalVisible = false;
    },
    deleteDateFilter() {
      this.activeDashboardUIStore.setValue(this.datePickerTarget, undefined);
    },
  },
};
</script>

<style lang="scss">
@import '../../styles/element-variables.scss';
@import '../../styles/filter-set-styles.scss';

.date-filter {
  display: inline-block;
  .header {
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  ::v-deep {
    .el-button {
      background: white;
    }
    .date-selector-btn {
      @extend .filter-button-base-style;
      line-height: 22px;
      text-align: left;
      width: 100%;
    }
  }
}
.date-subtext {
  font-size: 12px;
  color: $--neutral-500;
}
.date-filter-container {
  position: relative;

  .settings-dropdown.el-dropdown {
    position: absolute;
    right: 0;
    padding: 0;
    top: -5px;
  }
}
.dashboard-header,
.dashboard-date-selection {
  .date-filter-smart-label {
    .el-date-editor {
      visibility: hidden;
      height: 0px !important;
      max-height: 0px;
      max-width: 100px;
      padding: 0px;
      border: 0px;
    }
  }
}

.header {
  font-size: 1rem;
  line-height: 2rem;
  margin-bottom: 0.5em;
  text-align: left;
}

.el-button--medium.date-selector-btn {
  border-radius: unset;
  padding-block: 0.6em;
}
</style>
