import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExampleComments from 'components/ThemeEditor/ExampleComments';
import * as workerPath from 'file-loader?name=[name].[hash].js!././../ThemeEditor/CommentMatcher.worker';
import { getContextString } from 'lib/string-helper';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import { ConceptsEditorUIStoreInterface } from 'stores/ui/ConceptsEditorUIStore';
import NonDescriptorInput from './NonDescriptorInput';
import './term.scss';

interface TermProps {
  descriptor: string;
  nonDescriptor: string;
  conceptsEditorUIStore?: ConceptsEditorUIStoreInterface;
  themesStore?: ThemesStoreInterface;
}

interface TermState {
  exampleComments: string[];
  showNonDescriptorInput: boolean;
  processing: boolean;
  showPopup: boolean;
}

@inject('conceptsEditorUIStore', 'themesStore')
@observer
export default class Term extends React.Component<TermProps, TermState> {

  state = {
    exampleComments: [],
    showNonDescriptorInput: false,
    processing: false,
    showPopup: false
  };
  commentWorker?: Worker;

  get conceptsEditorUIStore() {
    return this.props.conceptsEditorUIStore!;
  }

  get themesStore() {
    return this.props.themesStore!;
  }

  showNonDescriptorInput = () => {
    const { isDuplicate, isEmpty, existsAsDescriptor } = this.conceptsEditorUIStore.nonDescriptorErrors;
    if (
      !isDuplicate &&
      !isEmpty &&
      !existsAsDescriptor
    ) {
      this.conceptsEditorUIStore.updateDescriptorInEdit('');
      this.setState({ showNonDescriptorInput: true });
      if (this.commentWorker) {
        this.commentWorker.terminate();
      }
    }
  }

  handleBlur = (nonDescriptorValue: string) => {
    const { isDuplicate, isEmpty, existsAsDescriptor } = this.conceptsEditorUIStore.nonDescriptorErrors;
    if (
      !isDuplicate &&
      !isEmpty &&
      !existsAsDescriptor
    ) {
      this.setState({ showNonDescriptorInput: false });
      this.conceptsEditorUIStore.updateNonDescriptor(
        nonDescriptorValue, this.props.descriptor, this.props.nonDescriptor
      );
    } else {
      this.setState({ showNonDescriptorInput: false });
      this.conceptsEditorUIStore.validateNonDescriptor(
        this.props.nonDescriptor, this.props.nonDescriptor);
    }
  }

  initalizeComments = () => {
    this.commentWorker = new Worker(workerPath.default);
    this.commentWorker.onmessage = (ev: MessageEvent) => {
      const exampleComments = ev.data['comments'] as string[];
      const comments = exampleComments.map(val => {
        return getContextString(val, this.props.nonDescriptor);
      });
      this.setState({ exampleComments: comments, processing: false });
    };
    this.commentWorker.onerror = () => {
      this.setState({ processing: false });
    };
  }

  showComments = () => {
    this.initalizeComments();
    const { exampleComments } = this.themesStore;
    const phrases = [this.props.nonDescriptor.toLowerCase()];
    const invertResults = false;
    const resultsSize = 5;
    if (this.commentWorker) {
      this.setState({ processing: true });
      const comments = [...exampleComments];
      this.commentWorker.postMessage({
        comments,
        phrases,
        invertResults,
        resultsSize
      });
    }
  }

  render() {
    const { descriptor, nonDescriptor } = this.props;
    const {
      showNonDescriptorInput,
      exampleComments,
      processing,
      showPopup
    } = this.state;
    return (
      <div>
        <div className="term">
          {showNonDescriptorInput ?
            <NonDescriptorInput
              nonDescriptor={nonDescriptor}
              showNonDescriptorInput={showNonDescriptorInput}
              onHandleBlur={(nonDescriptorValue) => this.handleBlur(nonDescriptorValue)}
            />
            :
            <Popup
              className="term-example-phrases"
              position="bottom left"
              wide="very"
              content={
                // Need this condition here to make sure this content doesn't render unless hovered on term
                showPopup &&
                <div>
                  <Popup.Header>Example phrases</Popup.Header>
                  {exampleComments.length > 0 ?
                    <div className="term-example-comments">
                      <ExampleComments
                        comments={exampleComments}
                        phrases={[nonDescriptor]}
                        processing={processing}
                        processerror={false}
                        istruncated={true}
                      />
                    </div>
                    :
                    <div>No phrases available for this term.</div>
                  }
                </div>
              }
              trigger={
                <div
                  className="non-descriptor"
                  onClick={this.showNonDescriptorInput}
                  style={{ width: (((nonDescriptor.length) + 2) + 'ch') }}
                  onMouseEnter={() => {
                    this.setState({ showPopup: true });
                    this.showComments();
                  }}
                  onMouseLeave={() => {
                    this.setState({ showPopup: false });
                    if (this.commentWorker) {
                      this.commentWorker.terminate();
                    }
                  }}
                >
                  {nonDescriptor}
                </div>
              }
            />
          }
          <Button
            type="button"
            size="medium"
            className="delete"
            onClick={() =>
              this.conceptsEditorUIStore.deleteNonDescriptor(descriptor, nonDescriptor)
            }
            icon={
              <FontAwesomeIcon
                icon="trash-alt"
                fixedWidth={true}
              />
            }
          />
        </div>
      </div>
    );
  }
}
