import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalActions, ModalContent, ModalHeader } from 'components/Shared/Modal';
import ExampleComments from 'components/ThemeEditor/ExampleComments';
import * as workerPath from 'file-loader?name=[name].[hash].js!././../ThemeEditor/CommentMatcher.worker';
import { getContextString } from 'lib/string-helper';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import { ConceptsEditorUIStoreInterface } from 'stores/ui/ConceptsEditorUIStore';
import DescriptorInput from './DescriptorInput';
import NewTerm from './NewTerm';
import Term from './Term';
import './concept.scss';

interface ConceptProps {
  descriptor: string;
  nonDescriptors: string[];
  showAddNewTerm: boolean;
  conceptsEditorUIStore?: ConceptsEditorUIStoreInterface;
  themesStore?: ThemesStoreInterface;
}

interface ConceptState {
  showDescriptorInput: boolean;
  isSwapModalVisible: boolean;
  exampleComments: string[];
  processing: boolean;
  showPopup: boolean;
}

@inject('conceptsEditorUIStore', 'themesStore')
@observer
export default class Concept extends React.Component<ConceptProps, ConceptState> {

  state = {
    showDescriptorInput: false,
    isSwapModalVisible: false,
    exampleComments: [],
    processing: false,
    showPopup: false
  };
  commentWorker?: Worker;

  get conceptsEditorUIStore() {
    return this.props.conceptsEditorUIStore!;
  }

  get themesStore() {
    return this.props.themesStore!;
  }

  showDescriptorInput = () => {
    const {
      hasMoreThanTwoWords,
      isDuplicate,
      isEmpty,
      existsAsNonDescriptor
    } = this.conceptsEditorUIStore.descriptorErrors;
    if (
      !hasMoreThanTwoWords &&
      !isDuplicate &&
      !isEmpty &&
      !existsAsNonDescriptor
    ) {
      this.conceptsEditorUIStore.updateDescriptorInEdit('');
      this.setState({ showDescriptorInput: true });
      if (this.commentWorker) {
        this.commentWorker.terminate();
      }
    }
  }

  handleBlur = (newDescriptorValue: string) => {
    const {
      hasMoreThanTwoWords,
      isDuplicate,
      isEmpty,
      existsAsNonDescriptor
    } = this.conceptsEditorUIStore.descriptorErrors;
    if (
      !hasMoreThanTwoWords &&
      !isDuplicate &&
      !isEmpty &&
      !existsAsNonDescriptor
    ) {
      this.setState({ showDescriptorInput: false }, () => {
        this.conceptsEditorUIStore.updateDescriptorInEdit('');
        this.conceptsEditorUIStore.updateDescriptor(newDescriptorValue, this.props.descriptor);
      });
    } else {
      this.setState({ showDescriptorInput: false });
      this.conceptsEditorUIStore.validateDescriptor(this.props.descriptor, this.props.descriptor);
    }
  }

  showNonDescriptorInput = () => {
    this.conceptsEditorUIStore.updateDescriptorInEdit(this.props.descriptor);
    this.conceptsEditorUIStore.toggleCreateNonDescriptorOptions(true);
  }

  closeNonDescriptorInput = () => {
    this.conceptsEditorUIStore.updateDescriptorInEdit('');
  }

  cancelCreateNonDescriptor = () => {
    this.conceptsEditorUIStore.updateDescriptorInEdit('');
    this.conceptsEditorUIStore.toggleCreateNonDescriptorOptions(false);
  }

  swapDescriptor = (value) => {
    const { isSwapModalVisible } = this.state;
    if (isSwapModalVisible) {
      this.conceptsEditorUIStore.swapDescriptor(this.props.descriptor, value);
    }
  }

  openSwapModal = () => {
    this.setState({ isSwapModalVisible: true });
  }

  closeSwapModal = () => {
    this.setState({ isSwapModalVisible: false });
  }

  initalizeComments = () => {
    this.commentWorker = new Worker(workerPath.default);
    this.commentWorker.onmessage = (ev: MessageEvent) => {
      const exampleComments = ev.data['comments'] as string[];
      const comments = exampleComments.map(val => {
        return getContextString(val, this.props.descriptor);
      });
      this.setState({ exampleComments: comments, processing: false });
    };
    this.commentWorker.onerror = () => {
      this.setState({ processing: false });
    };
  }

  showComments = () => {
    this.initalizeComments();
    const { exampleComments } = this.themesStore;
    const phrases = [this.props.descriptor];
    const invertResults = false;
    const resultsSize = 5;
    if (this.commentWorker) {
      this.setState({ processing: true });
      const comments = [...exampleComments];
      this.commentWorker.postMessage({
        comments,
        phrases,
        invertResults,
        resultsSize
      });
    }
  }

  render() {
    const {
      descriptor,
      nonDescriptors,
      showAddNewTerm
    } = this.props;
    const {
      showDescriptorInput,
      isSwapModalVisible,
      exampleComments,
      processing,
      showPopup
    } = this.state;
    return (
      <div className="concept">
        <div>
          {showDescriptorInput ?
            <DescriptorInput
              descriptor={descriptor}
              onHandleBlur={(newDescriptorValue) => this.handleBlur(newDescriptorValue)}
            />
            :
            <div className="descriptor-row">
              <div
                className="descriptor"
                onClick={this.showDescriptorInput}
                onMouseEnter={() => this.setState({ showPopup: true })}
                onMouseLeave={() => this.setState({ showPopup: false })}
              >
                <Popup
                  className="descriptor-example-phrases"
                  position="bottom left"
                  wide="very"
                  content={
                    // Need this condition here to make sure this content doesn't render unless hovered on term
                    showPopup &&
                    <div>
                      <Popup.Header>Example phrases</Popup.Header>
                      {exampleComments.length > 0 ?
                        <div className="descriptor-example-comments">
                          <ExampleComments
                            comments={exampleComments}
                            phrases={[descriptor]}
                            processing={processing}
                            processerror={false}
                            istruncated={true}
                          />
                        </div>
                        :
                        <div>No phrases available for this term.</div>
                      }
                    </div>
                  }
                  trigger={
                    <div
                      className="popup-trigger"
                      onMouseEnter={this.showComments}
                      onMouseLeave={() => {
                        if (this.commentWorker) {
                          this.commentWorker.terminate();
                        }
                      }}
                    >
                      {descriptor}
                    </div>
                  }
                />
              </div>
              <div className="swap-term">
                <Button
                  type="button"
                  className="swap"
                  size="tiny"
                  onClick={this.openSwapModal}
                  icon={
                    <FontAwesomeIcon
                      icon="exchange-alt"
                      fixedWidth={true}
                      rotation={90}
                    />
                  }
                />
              </div>
            </div>
          }
          <div className="non-descriptors">
            {nonDescriptors.map((value) => (
              <Term
                key={value}
                descriptor={descriptor}
                nonDescriptor={value}
              />
            ))}

            {!showAddNewTerm ?
              <Button
                type="button"
                className="add-non-descriptor"
                size="small"
                onClick={this.showNonDescriptorInput}
              >
                <FontAwesomeIcon
                  className="icon"
                  fixedWidth={true}
                  icon="plus"
                />
                Add term
              </Button>
              :
              <NewTerm
                descriptor={descriptor}
                onCancelCreateNonDescriptor={this.cancelCreateNonDescriptor}
                onCloseNonDescriptorInput={this.closeNonDescriptorInput}
              />
            }
          </div>
        </div>
        <div className="delete-concept">
          <Button
            type="button"
            className="delete"
            size="large"
            onClick={() => this.conceptsEditorUIStore.deleteConcept(descriptor)}
            icon={
              <FontAwesomeIcon
                icon="trash-alt"
                fixedWidth={true}
              />
            }
          />
        </div>
        <Modal
          open={isSwapModalVisible}
          onClose={this.closeSwapModal}
          className="swap-modal"
        >
          <ModalHeader as="h2">
            Click on the term with which you want to swap.
          </ModalHeader>
          <ModalContent>
            <div>
              {nonDescriptors.map((value) => (
                <Button
                  key={value}
                  onClick={() => this.swapDescriptor(value)}
                >
                  {value}
                </Button>
              ))}
            </div>
          </ModalContent>
          <ModalActions>
            <Button
              type="button"
              color="blue"
              onClick={this.closeSwapModal}
            >
              Cancel
            </Button>
          </ModalActions>
        </Modal>
      </div>
    );
  }
}
