import { AnswersDataSet, ErrorMessage } from 'api/interfaces';
import FeatureFeedbackButton from 'components/FeatureFeedback/FeatureFeedbackButton';
import { OpenFilterButton } from 'components/Filters/OpenFilterButton';
import { filterPopUpId } from 'components/Filters/types';
import * as React from 'react';
import { Consumer } from '../utils/context';
import { focusAskFormInput } from '../utils/focusAskFormInput';
import { getErrorTitle } from '../utils/getErrorTitle';
import { getSavedAnswerId } from '../utils/getSavedAnswerId';
import { Context } from './Context';
import { Warning } from './Warning';
import './not-enough-data.scss';
import NoDataIcon from 'images/icons/no-data.svg';

interface Props {
  question: string | null;
  error: Extract<ErrorMessage, { errorCode: 'NOT_ENOUGH_DATA' }>;
  datasets: AnswersDataSet[];
}

export const NotEnoughData = (props: Props) => {
  const data = props.error.data[0];
  const title = getErrorTitle(props.question);

  const feedbackData = {
    error: props.error,
    timestamp: new Date().toISOString(),
    question: props.question,
  };
  const savedAnswerId = getSavedAnswerId();
  const locationName = `answers:${ savedAnswerId || 'new' }`;

  if (!data) {
    // Doubtful that there would be no data; handling it just in case.
    return (
      <Warning
        title={props.error.error}
      />
    );
  }

  return (
    <Consumer>
      {({ askParams }) => (
        <section className="answers__not-enough-data">
          <h4 className="answers__not-enough-data-title">{title}</h4>
          <Context
            answer={data}
            dataSets={props.datasets}
          />
          <Warning
            title="Sorry, but we don't have the data to answer your query with these filters."
            icon={<NoDataIcon className="no-data-icon" />}
          >
            <p
              className="answers-warning__description"
              data-testid="advice"
            >
              If you think the filters need tweaking, go ahead and make some changes. Otherwise, if you believe the filters are right but you're still missing data, please give us some feedback.
            </p>
          </Warning>
          <div className="answers__not-enough-data-toolbar" role="toolbar">
            <FeatureFeedbackButton
              featureId="answers"
              location={locationName}
              rating={1}
              modalTextConfig={{
                title: 'Give feedback',
                subTitle: 'Your feedback helps us to continuously improve our answers.',
                textAreaPlaceholder: 'Tell us what is missing in your data, or give any other feedback.'
              }}
              data={feedbackData}
              buttonSubvariant='neutral-text'
              onModalUnmount={() => focusAskFormInput()}
            />
            {askParams.dataSets.length === 1 && (
              <OpenFilterButton
                filterPopUpId={filterPopUpId.Answers}
              >
                Change filters
              </OpenFilterButton>
            )}
          </div>
        </section>
      )}
    </Consumer>
  );
};
