import * as React from 'react';
import { observer } from 'mobx-react';
import { debounce, isEqual } from 'lodash';
import { VueInReact } from 'vuera';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import { Button } from 'components/Shared/Button';
import analytics from 'lib/analytics';
import { ThemeFilterSet } from 'stores/ui/AnalysisToolsUIStore';
import { FilterStoreInterface } from 'stores/FilterStore';
import queryBuilder from 'vue/libs/queryBuilder';
import { refineClusters } from 'lib/refine-clusters';
import thematicData, { UrlOptions } from 'vue/libs/thematicData';
import { RefinedCluster } from 'lib/refine-clusters';
import { PlainComment, Thread } from 'types/custom';
import { AnalysisFilterKey } from 'stores/types';
import { IReactionDisposer, reaction } from 'mobx';
import OverviewVue from 'vue/explore-detail/Overview.vue';
import './overview-section.scss';
import {
  getAnalysisConfigStore,
  getAnalysisToolsKeyTakeawaysUIStore,
  getAnalysisToolsUIStore,
  getExploreToolUIStore,
  getFilterStore,
  getInitConfigStore,
  getQuickEditUIStore,
  getThemesStore,
} from 'stores/RootStore';
import { Theme } from 'vue/explore/theme-data-functions';
import { compose } from 'lib/composeHOCs';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import { FilterSelections } from 'stores/FilterStore';

const Overview = VueInReact(OverviewVue);

const INITIAL_SENTENCE_LIMIT = 6;

type Props = {
  selectedThemeObject?: Theme,
  themeName?: string,
  subthemeName?: string,
};

type State = {
  hasSummaryRequestError: boolean,
  isLoading: boolean,
  summaries: {
    baseline: string,
    comparison: string
  },
  clusters: {
    baseline: RefinedCluster<PlainComment | Thread>[],
    comparison: RefinedCluster<PlainComment | Thread>[]
  }
};

const withHocs = compose(observer, withCamelCaseProps);

export const OverviewSection = withHocs(class OverviewSectionClass extends React.Component<Props, State> {
  disposer: IReactionDisposer;
  state = {
    hasSummaryRequestError: false,
    isLoading: true,
    summaries: {
      baseline: '',
      comparison: ''
    },
    clusters: {
      baseline: [] as RefinedCluster<PlainComment | Thread>[],
      comparison: [] as RefinedCluster<PlainComment | Thread>[]
    }
  };

  componentDidMount() {
    let previousThemeCode = this.analysisToolsUIStore.themeFilterSet.theme.code;
    let previousSubThemeCode = this.analysisToolsUIStore.themeFilterSet.subtheme.code;
    let previousFilterSelections = this.filterStore.selections;

    this.disposer = reaction(
      () => ([this.analysisToolsUIStore.themeFilterSet, this.filterStore.selections] ),
      ([themeFilterSet, filterSelections]: [ThemeFilterSet, FilterSelections]) => {
        if (Object.keys(themeFilterSet).length === 0) {
          return;
        }

        const currentThemeCode = themeFilterSet.theme.code;
        const currentSubThemeCode = themeFilterSet.subtheme.code;

        const themeChanged = currentThemeCode !== previousThemeCode;
        const subthemeChanged = currentSubThemeCode !== previousSubThemeCode;
        const filterSelectionsChanged = !isEqual(previousFilterSelections, filterSelections);

        if (themeChanged || subthemeChanged || filterSelectionsChanged) {
          this.makeSummariesRequests();
          previousThemeCode = currentThemeCode;
          previousSubThemeCode = currentSubThemeCode;
          previousFilterSelections = filterSelections;
        }
      },
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    this.disposer?.();
  }

  get analysisConfigStore() {
    return getAnalysisConfigStore();
  }

  get analysisToolsKeyTakeawaysUIStore() {
    return getAnalysisToolsKeyTakeawaysUIStore();
  }

  get analysisToolsUIStore() {
    return getAnalysisToolsUIStore();
  }

  get exploreToolUIStore() {
    return getExploreToolUIStore();
  }

  get filterStore() {
    return getFilterStore();
  }

  get initConfigStore() {
    return getInitConfigStore();
  }

  get themesStore() {
    return getThemesStore();
  }

  get quickEditUIStore() {
    return getQuickEditUIStore();
  }

  get hasKeyTakeaways() {
    const { frequency, impact } = this.keyTakeaways;
    return Boolean(frequency && impact);
  }

  get selectedThemeTitle() {
    const theme = this.props.themeName;
    const subtheme = this.props.subthemeName;
    if (subtheme) {
      return `${theme}: ${subtheme}`;
    }
    return theme;
  }

  get hasComparisonFilters() {
    const { selections } = this.filterStore;
    return selections.baseline.query !== selections.comparison.query;
  }

  get hasComparisonSummary() {
    return this.hasSummaryFeature && this.state.summaries.comparison.length > 0;
  }

  get keyTakeaways() {
    const keyTakeaways = this.analysisToolsKeyTakeawaysUIStore.takeaways || {};
    const frequency = (keyTakeaways['frequency-chart'] || []).reduce((acc, item) => item, null);
    const impact = (keyTakeaways['impact-chart'] || []).reduce((acc, item) => item, null);
    return { frequency, impact };
  }

  get themeName() {
    const { themeFilterSet } = this.analysisToolsUIStore;
    const { theme, subtheme } = themeFilterSet;
    return subtheme.name ? subtheme.name : theme.name;
  }


  get hasSummaryFeature() {
    return FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_THEMES_SUMMARIZATION);
  }

  get isCallCenterSummary() {
    const { config } = this.analysisConfigStore;
    return FeatureFlagManager.checkFlag(FlagKeys.USE_CLUSTERING_TEXT_TYPE) && !!config?.threadConfig?.summarize;
  }

  fetchAllSummariesDebounced = debounce(async (
    selectedTheme: ThemeFilterSet,
    segmentQueryStrings: FilterStoreInterface['segmentQueryStrings'],
    baselineQuery: string,
    comparisonQuery: string
  ) => {
    const promises = [
      this.fetchSummaries(
        'baseline',
        selectedTheme,
        segmentQueryStrings.baseline,
        baselineQuery
      )
    ];

    if (this.hasComparisonFilters) {
      promises.push(
        this.fetchSummaries(
          'comparison',
          selectedTheme,
          segmentQueryStrings.comparison,
          comparisonQuery
        )
      );
    }

    this.setState({ hasSummaryRequestError: false, isLoading: true });

    try {
      await Promise.all(promises);
    } catch (e) {
      this.setState({ hasSummaryRequestError: true });
    }

    this.setState({ isLoading: false });
  }, 10);

  fetchSummaries = async (
    filterType: AnalysisFilterKey,
    selectedThemes: ThemeFilterSet,
    queryString: string | undefined,
    filter: string
  ) => {
    if (!this.hasSummaryFeature) {
      this.setState({ isLoading: false });
      return;
    }

    const base = selectedThemes.theme.code || '';
    const sub = selectedThemes.subtheme.code || null;
    const sentiment = 'all';

    const summaryContext = this.exploreToolUIStore.selectedThemeName;

    const segmentSelection = selectedThemes
      ? queryBuilder.appendThemesToSegmentFilter(queryString || '', base, sub || undefined)
      : queryString;

    const baseOptions: UrlOptions<{}> = {
      ...this.initConfigStore.requestOptions,
      segmentSelection,
      summaryContext,
      version: "5",
      type: 'default',
    };

    if (this.isCallCenterSummary) {
      baseOptions.textType = "callCenterSummary";
    }

    try {

      const payload = FeatureFlagManager.checkFlag(FlagKeys.USE_PARALLEL_SUMMARY_REQUESTS);

      if (payload !== 'CONTROL') {

        const summaryOptions = { ...baseOptions, summarize: true, cluster: false };
        const clusterOptions = { ...baseOptions, summarize: false, cluster: true };

        const start = performance.now();
        const summaryRequest = thematicData.getSummarization(
          filter,
          base,
          sub || undefined,
          sentiment,
          summaryOptions,
          false,
          'exploreToolThemeSummary'
        );
        const clusterRequest = thematicData.getSummarization(
          filter,
          base,
          sub || undefined,
          sentiment,
          clusterOptions,
          false,
          'exploreToolThemeSummary'
        );

        const [summaryData, clusterData] = await Promise.all([summaryRequest, clusterRequest]);
        const end = performance.now();
        const duration = end - start;
        analytics.track('Parallel Summarization A/B', {
          'Group': 'parallel',
          'Duration': duration
        });

        this.setState({
          summaries: {
            ...this.state.summaries,
            [filterType]: summaryData.data.summary
          },
          clusters: {
            ...this.state.clusters,
            [filterType]: refineClusters(clusterData.data.clusters)
          }
        });

      } else {

        const options = { ...baseOptions, summarize: true, cluster: true };

        const start = performance.now();
        const data = await thematicData.getSummarization(
          filter,
          base,
          sub || undefined,
          sentiment,
          options,
          false,
          'exploreToolThemeSummary'
        );
        const end = performance.now();
        const duration = end - start;
        analytics.track('Parallel Summarization A/B', {
          'Group': 'single',
          'Duration': duration
        });

        this.setState({
          summaries: {
            ...this.state.summaries,
            [filterType]: data.data.summary
          },
          clusters: {
            ...this.state.clusters,
            [filterType]: refineClusters(data.data.clusters)
          }
        });
      }
    } catch (e) {
      if (e.code !== 'ERR_CANCELED') {
        throw e;
      }
    }
  };

  makeSummariesRequests = () => {
    const selectedTheme = this.analysisToolsUIStore.themeFilterSet;
    const selections = this.filterStore.selections;
    const themesFilter = this.filterStore.themesFilter;
    const segmentQueryStrings = this.filterStore.segmentQueryStrings;

    const baselineQuery = queryBuilder.appendToFilter(
      selections.baseline.query,
      themesFilter.baselineRql || ''
    );

    const comparisonQuery = queryBuilder.appendToFilter(
      selections.comparison.query,
      themesFilter.comparisonRql || '',
    );

    if (!selectedTheme.theme || !selectedTheme.theme.code || selectedTheme.theme.code === '') {
      return;
    }

    this.fetchAllSummariesDebounced(
      selectedTheme,
      segmentQueryStrings,
      baselineQuery,
      comparisonQuery
    );
  };

  retrySummaryRequests = () => {
    thematicData.clearSummarizationCache();
    this.makeSummariesRequests();
  };

  onSummaryTextSelection = () => {
    const selectedText = window.getSelection() as Selection;
    if (selectedText.toString().trim().length > 0) {
      analytics.track('Analysis: Select Summary', {
        'Location': 'Theme',
      });
    }
  };

  render() {
    const {
      hasSummaryRequestError,
      isLoading,
      summaries,
      clusters,
    } = this.state;

    const selectedThemeTitle = this.selectedThemeTitle;
    const selectedThemeObject:Theme | undefined = this.props.selectedThemeObject;

    const baselineCount = this.exploreToolUIStore.baselineCount;
    const comparisonCount = this.exploreToolUIStore.comparisonCount;

    const baselineVolume = {
      value: selectedThemeObject?.baseCount || 0,
      total: baselineCount || 0,
    };
    const comparisonVolume = {
      value: selectedThemeObject?.compCount || 0,
      total: comparisonCount || 0,
    };
    const baselineSentiment = {
      value: selectedThemeObject?.baseSentiment || 0,
      segments: selectedThemeObject?.baseSentiments,
    };
    const comparisonSentiment = {
      value: selectedThemeObject?.compSentiment || 0,
      segments: selectedThemeObject?.compSentiments,
    };

    const isBaselineEmpty = baselineVolume.value === 0;
    const isComparisonEmpty = comparisonVolume.value === 0;

    const baselineSummary = summaries.baseline;
    const comparisonSummary = summaries.comparison;

    const { canManageThemes, hasSentiment, threadDisplayConfig } = this.analysisConfigStore;
    const { areSupportMessagesVisible, selectedThemeCodes } = this.analysisToolsUIStore;
    const { editedComments}  = this.quickEditUIStore;
    const { applying: isApplyingThemes, themesHierarchy, transforms, } = this.themesStore;

    const baselineClusters = clusters.baseline;
    const comparisonClusters = clusters.comparison;

    const hasKeyTakeaways = this.hasKeyTakeaways;
    const hasSummary = this.hasSummaryFeature;
    const keyTakeaways = this.keyTakeaways;
    const themeName = this.themeName;

    return (
      <div className="overview-section explore-detail__widget">
        {isLoading ? (
          <section className="overview-section__loading">
            <div className="ui active centered inline loader" />
            <h2>Summarizing data...</h2>
            <span>This may take up to 30 seconds.</span>
          </section>
        ):(
          <div>
            <Overview
              areSupportMessagesVisible={areSupportMessagesVisible}
              baselineClusters={baselineClusters}
              baselineSentiment={baselineSentiment}
              baselineSummary={baselineSummary}
              baselineVolume={baselineVolume}
              canManageThemes={canManageThemes}
              comparisonClusters={comparisonClusters}
              comparisonSentiment={comparisonSentiment}
              comparisonSummary={comparisonSummary}
              comparisonVolume={comparisonVolume}
              editedComments={editedComments}
              hasComparisonFilters={this.hasComparisonFilters}
              hasKeyTakeaways={hasKeyTakeaways}
              hasSentiment={hasSentiment}
              hasSummary={hasSummary}
              hasSummaryError={hasSummaryRequestError}
              isApplyingThemes={isApplyingThemes}
              isBaselineEmpty={isBaselineEmpty}
              isComparisonEmpty={isComparisonEmpty}
              keyTakeaways={keyTakeaways}
              selectedThemeCodes={selectedThemeCodes}
              selectedThemeTitle={selectedThemeTitle}
              sentenceLimit={INITIAL_SENTENCE_LIMIT}
              themeName={themeName}
              themesHierarchy={themesHierarchy}
              threadDisplayConfig={threadDisplayConfig}
              transforms={transforms}
              onRetrySummary={this.retrySummaryRequests}
              onSummaryTextSelection={this.onSummaryTextSelection}
            />
          </div>
        )}
        {hasSummaryRequestError && (
          <section className="sentence-section__error">
            <FontAwesomeIcon icon="exclamation-triangle" />
            <span className="text">Something went wrong while loading the sentences.</span>
            <Button
              type="text"
              onClick={this.retrySummaryRequests}
            >
            Click here to try again
            </Button>
          </section>
        )}
      </div>
    );
  }
});
