import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowAltDown,
  faArrowAltUp,
  faCalendarAlt,
  faChartLine,
  faCheckCircle,
  faChevronDoubleDown,
  faChevronDoubleUp,
  faChevronUp,
  faCloudDownloadAlt,
  faCog,
  faCommentDots,
  faCopy,
  faCut,
  faEllipsisV,
  faExchangeAlt,
  faExclamationTriangle,
  faFileCsv,
  faFileImage,
  faFilePowerpoint,
  faFlag,
  faFrown,
  faHome,
  faHourglassHalf, faInfoCircle, faLink,
  faMeh,
  faProjectDiagram,
  faQuestionCircle,
  faSmile,
  faSpinner,
  faThumbsDown,
  faThumbsUp,
  faTilde,
  faTimes,
  faClone,
  faArrowLeft,
  faList
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { LocalStorageKeys } from 'api/enums';
import { ExportAssistantModal } from 'components/ExportAssistant/ExportAssistantModal';
import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import { Location } from 'history';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { matchPath } from 'react-router';
import { Prompt, RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Dimmer, Loader, Modal, Segment } from 'semantic-ui-react';
import { AnalysisConfigStoreInterface } from 'stores/AnalysisConfigStore';
import { SegmentStoreInterface } from 'stores/SegmentStore';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import { toConfigUrl } from 'stores/surveys/config-parser';
import { AnalysisToolsUIStoreInterface } from 'stores/ui/AnalysisToolsUIStore';
import { QuickEditUIStoreInterface } from 'stores/ui/QuickEditUIStore';
import Vue from 'vue';
import Router from 'vue-router';
import AnalysisVue from 'vue/Analysis.vue';
import 'vue/assets/css/thematic-old/thematic-old.scss';
import ExportInsightVue from 'vue/components/Insights/ExportInsight.vue';
import { applyThemeChanges } from 'vue/libs/quick-edits';
import thematicData from 'vue/libs/thematicData';
import 'vue/styles/element-style.scss';
import { VueInReact } from 'vuera';
import './vis.scss';
import { InitConfigStoreInterface } from 'stores/InitConfigStore';
import { TagStoreInterface } from 'stores/TagStore';
import analytics from 'lib/analytics';

library.add(
  faArrowAltDown,
  faArrowAltUp,
  faChartLine,
  faTimes,
  faChevronDoubleDown,
  faChevronDoubleUp,
  faChevronUp,
  faCloudDownloadAlt,
  faCog,
  faCut,
  faExchangeAlt,
  faExclamationTriangle,
  faFlag,
  faFrown,
  faHome,
  faLink,
  faMeh,
  faProjectDiagram,
  faQuestionCircle,
  faSmile,
  faSpinner,
  faTilde,
  faArrowAltUp,
  faArrowAltDown,
  faHourglassHalf,
  faCopy,
  faCheckCircle,
  faFileCsv,
  faFilePowerpoint,
  faFileImage,
  faThumbsUp,
  faThumbsDown,
  faInfoCircle,
  faCommentDots,
  faCalendarAlt,
  faEllipsisV,
  faClone,
  faArrowLeft,
  faList
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Element, { locale });
Vue.use(Router);

const router = new Router();
const Analysis = VueInReact({
  router,
  ...AnalysisVue,
});

const ExportInsight = VueInReact({...ExportInsightVue});

export interface VisStoreProps {
  analysisToolsUIStore: AnalysisToolsUIStoreInterface;
  segmentStore: SegmentStoreInterface;
  themesStore: ThemesStoreInterface;
  quickEditUIStore: QuickEditUIStoreInterface;
  analysisConfigStore: AnalysisConfigStoreInterface;
  initConfigStore: InitConfigStoreInterface;
  tagStore: TagStoreInterface;
}

interface VisProps extends VisStoreProps, RouteComponentProps<VisParams> { }

export interface VisParams {
  orgId: string;
  surveyId: string;
  viewId: string;
  visId: string;
  tool: string;
}

interface VisState {
  blinking: boolean;
  scrolledToTop: boolean;
  modalVisible: boolean;
  toLocation: object;
  confirmedNavigation: boolean;
  configError: Error | null;
  isLoading: boolean;
}

@inject(
  'analysisConfigStore',
  'analysisToolsUIStore',
  'segmentStore',
  'quickEditUIStore',
  'themesStore',
  'initConfigStore',
  'tagStore',
)
@observer
class Vis extends React.Component<VisProps, VisState> {
  state = {
    configError: null,
    blinking: false,
    scrolledToTop: true,
    modalVisible: false,
    toLocation: {
      hash: '',
      pathname: '',
      search: '',
      state: undefined,
    },
    confirmedNavigation: false,
    isLoading: true,
  };
  updateBodyClass(tool?: string) {

    const classList = document.body.classList;

    Array.from(classList)
      .filter(c => c.startsWith('analysis-tab'))
      .forEach(c => classList.remove(c));

    if (tool) {
      classList.add('analysis-tab');
      classList.add(`analysis-tab--${tool}`);
    }

  }
  componentDidMount() {
    const { surveyId, visId, viewId, tool } = this.props.match.params;
    const { analysisToolsUIStore, segmentStore } = this.props;

    analysisToolsUIStore!.setCurrentTool(tool);
    analysisToolsUIStore?.initializeAnalysisToolsUI(surveyId, visId, viewId);
    segmentStore.setDatasetIdentifiers({ surveyId, viewId });

    localStorage.setItem(LocalStorageKeys.HAS_VIEWED_ANALYSIS, 'true');
    document.addEventListener('scroll', this.onDocumentScroll);

    this.updateBodyClass(tool);
    this.initConfig();
  }
  componentDidUpdate(prevProps: VisProps) {
    const { surveyId, visId, viewId, tool } = this.props.match.params;
    const {
      analysisConfigStore,
      analysisToolsUIStore,
      segmentStore,
      themesStore,
    } = this.props;
    const { params: prevParams } = prevProps.match;

    const canManageThemes = analysisConfigStore?.canManageThemes;
    const canReadThemes = analysisConfigStore?.canReadThemes;

    if (analysisConfigStore.config && 'threadConfig' in analysisConfigStore.config) {
      analytics.startRecording(false);
    } else {
      analytics.stopRecording();
    }

    if (canManageThemes && canReadThemes && !themesStore.statusTimeoutId) {
      themesStore.pollStatus(surveyId);
    }

    if (tool !== prevParams.tool) {
      analysisToolsUIStore!.setCurrentTool(tool);
      this.updateBodyClass(tool);
    }

    if (surveyId !== prevParams.surveyId || visId !== prevParams.visId || viewId !== prevParams.viewId) {
      this.initConfig();
      segmentStore.setDatasetIdentifiers({ surveyId, viewId });
      analysisToolsUIStore?.initializeAnalysisToolsUI(surveyId, visId, viewId);
      this.setState({ confirmedNavigation: false });
    }
  }
  componentWillUnmount() {
    const {
      analysisToolsUIStore,
      themesStore,
      segmentStore,
      analysisConfigStore,
    } = this.props;
    thematicData.resetAllDBs();
    analysisToolsUIStore?.reset();

    segmentStore.setDatasetIdentifiers({ surveyId: undefined, viewId: undefined });

    if (analysisConfigStore!.canManageThemes) {
      themesStore.cancelStatusPoll();
    }

    document.removeEventListener('scroll', this.onDocumentScroll);
    this.updateBodyClass();
    analytics.stopRecording();
  }
  initConfig = async () => {
    this.setState({ isLoading: true });
    const { analysisConfigStore, themesStore, initConfigStore, tagStore } = this.props;
    const { surveyId, visId, viewId, orgId } = this.props.match.params;
    const configUrl = toConfigUrl(surveyId, visId, viewId);

    try {
      initConfigStore.setConfigLocation(configUrl);
      const updatedConfig = await initConfigStore.getConfig();

      if (!updatedConfig) {
        throw new Error('Unable to load config.');
      }

      if (updatedConfig instanceof Error) {
        throw updatedConfig;
      }

      const { commentColumns, requestOptions } = initConfigStore;
      analysisConfigStore.setConfig(updatedConfig);
      await themesStore.initThemesHierarchy(requestOptions, commentColumns);
      await tagStore.getTags();
      const canManageThemes = this.props.analysisConfigStore.canManageThemes;
      if (surveyId && orgId && canManageThemes) {
        await this.props.themesStore.getThemes({ surveyId, orgId });
      }
      this.setState({ configError: null });
    } catch (error) {
      const configError = initConfigStore.configError || error;
      this.setState({ configError });
    } finally {
      this.setState({ isLoading: false });
    }
  }
  onDocumentScroll = () => {
    const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
    const atTop = scrollPosition === 0;
    if (atTop !== this.state.scrolledToTop) {
      this.setState({ scrolledToTop: atTop });
    }
  };
  showModal = () => {
    this.setState({ modalVisible: true });
  };
  closeModal = () => {
    this.setState({ modalVisible: false });
  };
  handleBlockedNavigation = (nextLocation: Location) => {
    const { surveyId } = this.props.match.params;
    const { confirmedNavigation } = this.state;

    const vMatch = matchPath<{ orgId: string; surveyId: string }>(nextLocation.pathname, {
      path: '/c/:orgId/s/:surveyId/v/*',
      exact: false,
    });

    if (confirmedNavigation) {
      return true;
    }
    if (vMatch && vMatch.params.surveyId === surveyId) {
      // we do not want to show warning modal if user is navigating within same survey
      return true;
    }

    this.showModal();
    this.setState({ toLocation: nextLocation });
    return false;
  };
  handleConfirmNavigationClick = () => {
    this.closeModal();
    const { toLocation } = this.state;
    if (toLocation && !!toLocation.pathname.length) {
      this.setState({ confirmedNavigation: true }, () => {
        this.props.quickEditUIStore?.removeEditedComments(this.props.match.params.surveyId);
        window.location.href = `/#${ toLocation.pathname }`;
      });
    }
  };
  handleApplyChangesClick = () => {
    this.closeModal();
    applyThemeChanges();
  }
  showExportAssistant() {
    return this.props.analysisToolsUIStore.exportAssistantIsOpen;
  }
  closeExportAssistant() {
    this.props.analysisToolsUIStore.closeExportAssistant();
  }
  getLoadingMessage() {
    if (this.props.initConfigStore.isLoadingConfig) {
      return 'Loading config...';
    }
    if (this.props.themesStore.isLoadingThemesHierarchy) {
      return 'Loading themes...';
    }
    if (this.props.themesStore.loading) {
      return 'Loading theme file...';
    }
    return 'Loading...';
  }
  render(): JSX.Element | null {
    const { configError, isLoading, modalVisible, scrolledToTop } = this.state;
    const { orgId, surveyId } = this.props.match.params;
    const styles = {
      margin: 0,
      padding: 0,
    };
    const shouldShowNavigationPrompt =
      this.props.themesStore?.transforms.length > 0 &&
      this.props.analysisConfigStore!.canManageThemes;

    return (
      <>
        <Prompt when={shouldShowNavigationPrompt} message={this.handleBlockedNavigation} />
        <Modal dimmer="blurring" open={modalVisible} onClose={this.closeModal} size="tiny">
          <Modal.Content>
            <div className="modal-content">
              Are you sure you want to leave this page? Any unsaved changes will be discarded.
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.closeModal} type="button"
              floated="left">
              Cancel
            </Button>
            <Button
              type="button"
              onClick={this.handleApplyChangesClick}
            >
              Apply changes
            </Button>
            <Button
              type="button"
              color="blue"
              onClick={this.handleConfirmNavigationClick}
            >
              Discard changes
            </Button>
          </Modal.Actions>
        </Modal>
        <Segment className="vis" style={styles}>
          {isLoading && (
            <Dimmer active inverted>
              <Loader active={true}>{this.getLoadingMessage()}</Loader>
            </Dimmer>
          )}
          {!isLoading && (
            <div>
              <Analysis
                configError={configError}
                isSticky={!scrolledToTop}
                orgId={orgId}
                surveyId={surveyId}
              />
            </div>
          )}
        </Segment>
        {this.showExportAssistant() && (
          <ExportAssistantModal
            onClose={() => this.closeExportAssistant()}
            open={true}
          />
        )}
        <div>
          <ExportInsight />
        </div>
      </>
    );
  }
}

export default withRouter(Vis);
