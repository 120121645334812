import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeCardDropdown } from './ThemeCardDropdown';
import { HoverHint, ThemeCardInterface } from './ThemeTree';
import './theme-card-actions.scss';

interface Props {
  groupId: string;
  id: string;
  onDelete: () => void;
  parentId: string | undefined;
  updateTheme: (item: ThemeCardInterface) => void;
  setUpdateCandidate: (updateCandidate?: HoverHint) => void;
}

const ThemeCardActions = ({ groupId, id, setUpdateCandidate, onDelete, parentId, updateTheme }: Props) => {
  const [showDropdown, setShowDropdown] = React.useState(false);

  const handleDropdownClose = () => {
    setShowDropdown(false);
  };

  const handleDropdownOpen = () => {
    setShowDropdown(true);
  };

  return (
    <div className="theme-card-actions">
      <ThemeCardDropdown
        updateTheme={updateTheme}
        groupId={groupId}
        id={id}
        setUpdateCandidate={setUpdateCandidate}
        open={showDropdown}
        onClose={handleDropdownClose}
        onDelete={onDelete}
        onOpen={handleDropdownOpen}
        parentId={parentId}
        trigger={
          <button className="theme-card-actions__button">
            <FontAwesomeIcon icon="ellipsis-v" />
          </button>
        }
      />
    </div>
  );
};

export { ThemeCardActions };
