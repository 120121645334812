<template>
  <widget-wrapper
    :title="title"
    :loading="loading"
    :error="error"
    :warning="warning"
    class="impact-with-subthemes ob-impact-with-subthemes"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        {{ subtitle }}
        <font-awesome-icon
          v-if="canNavigate"
          class="link-icon"
          icon="chart-bar"
        />
      </div>
    </div>
    <div
      :class="{ empty: empty }"
      class="themes-body widget-body"
    >
      <div
        v-if="!empty"
        class="section-header"
      >
        <h5>Base Theme</h5>
        <h5>Impact</h5>
        <h5>Subthemes</h5>
        <h5>Impact</h5>
      </div>
      <div
        v-for="(theme, index) in themes"
        :key="theme + index"
        class="theme-row"
        :aria-label="theme.name"
      >
        <div class="base-theme">
          <div
            v-if="!isWeighted"
            :class="{ 'clickable-theme': canNavigate }"
            class="theme-name"
            aria-label="theme name"
            @click="doNavigate({code: theme.code, title: theme.name})"
          >
            <el-tooltip
              :content="theme.name"
              placement="bottom"
              :open-delay="300"
            >
              <span>{{ theme.name }}</span>
            </el-tooltip>
          </div>
          <theme-name-with-sources
            v-else
            :source="source"
            tool="explore"
            subtool="impact"
            :theme="theme.name"
            :theme-components="theme.components"
            :theme-name="theme.name"
            :theme-sources="themeSources"
          />
          <bar
            :value="theme.impact"
            :max-value="maxValue"
            :range="range"
            :last-item="index + 1 == themes.length"
          />
        </div>
        <div class="subthemes">
          <div
            v-for="(subtheme, i) in theme.subthemes"
            :key="theme + i"
            class="subtheme"
          >
            <div
              v-if="!isWeighted"
              :class="{ 'clickable-theme': canClickTheme }"
              class="theme-name"
              @click="doNavigate({code: theme.code, title: theme.name},
                                 {code: subtheme.code, title: subtheme.name})"
            >
              <el-tooltip
                :content="subtheme.name"
                placement="bottom"
                :open-delay="300"
              >
                <span class="truncate">{{ subtheme.name }}</span>
              </el-tooltip>
            </div>
            <theme-name-with-sources
              v-else
              :source="source"
              tool="explore"
              subtool="impact"
              :theme="subtheme.name"
              :theme-components="subtheme.components"
              :theme-name="subtheme.name"
              :theme-sources="themeSources"
              :truncate-theme-name="true"
            />
            <bar
              :value="subtheme.impact"
              :narrow="true"
              :max-value="maxValue"
              :range="range"
              :last-item="index + 1 == theme.subthemes.length"
            />
          </div>
        </div>
      </div>
      <div
        v-if="empty"
        class="empty-theme-list"
      >
        {{ emptyMessage }}
      </div>
    </div>
    <summary-component
      v-if="isSummaryEnabled"
      :filters="filters"
      :theme="selectedTheme"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
    <comment-component
      v-if="isCommentsEnabled"
      :filter-string="filters.baseline"
      :theme="selectedTheme"
      :sentiment="sort === 'asc' ? 'negative' : 'positive'"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
  </widget-wrapper>
</template>

<script>
import { ReactInVue } from 'vuera';
import { Bar } from 'components/Dashboard/Widgets/Bar';
import ThemeNameWithSources from './ThemeNameWithSources';
import SummaryComponent from './SummaryComponent';
import CanNavigateMixin from 'vue/dashboards/Dashboard/MixIns/CanNavigateMixin';
import WidgetWrapper from '../WidgetWrapper';
import { CommentComponent as ReactCommentComponent } from 'components/Dashboard/Widgets/CommentComponent';

export default {
  name: 'IMPACTWITHSUBTHEMESCOMPONENT',
  components: {
    Bar: ReactInVue(Bar),
    ThemeNameWithSources,
    WidgetWrapper,
    SummaryComponent,
    CommentComponent: ReactInVue(ReactCommentComponent),
  },
  mixins: [CanNavigateMixin('explore', 'impact')],
  props: {
    filters: {type: Object},
    error: { type: String },
    warning: { type: String },
    loading: { default: false, type: Boolean },
    maxValue: { type: Number },
    range: { type: Array },
    scoreName: { type: String },
    showComments: { default: false, type: Boolean },
    showSummary: { default: false, type: Boolean },
    sort: { type: String },
    source: { type: String },
    title: { type: String },
    themes: { type: Array },
    themeSources: { type: Array },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  computed: {
    empty() {
      return !this.themes || !this.themes.length;
    },
    subtitle() {
      const { scoreName, sort } = this;
      const tokens = [];

      if (sort === 'asc') {
        tokens.push('Key negative drivers of');
      } else {
        tokens.push('Key positive drivers of');
      }

      if (scoreName) {
        tokens.push(scoreName);
      } else {
        tokens.push('score');
      }

      return tokens.join(' ');
    },
    emptyMessage() {
      if (this.sort === 'asc') {
        return 'No negative themes found';
      } else {
        return 'No positive themes found';
      }
    },
  },
  watch: {
    showComments: {
      handler (showComments) {
        this.isCommentsEnabled = showComments;
      },
      immediate: true
    },
    showSummary: {
      handler (showSummary) {
        this.isSummaryEnabled = showSummary;
      },
      immediate: true
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/element-variables.scss';
.dashboard-widget.widget.impact-with-subthemes {
  @media (max-width: 1070px) {
    flex: 1 1 calc(100% - 40px);
  }
}
.dashboard-widget .themes-body {
  padding-bottom: 0;
}
.section-header,
.theme-row {
  display: grid;
  margin: 0 -10px; // bleed background color
}
.section-header {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid $--neutral-200;
  padding-bottom: 0.5rem;
}
.section-header h5 {
  color: $--neutral-400 !important;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 19px;
  text-transform: uppercase;
  margin: 0;

  &:nth-child(even) {
    justify-self: end;
  }
  &:nth-child(2) {
    padding-right: 20px;
  }
}
.theme-row {
  grid-template-columns: 1fr 1fr;
  align-items: baseline;
  border-radius: 0;
  height: 100px;
  padding: 5px 20px;

  &:nth-child(odd) {
    background: $--neutral-100;
  }
}
.theme-row .clickable-theme {
  width: 50%;
  flex-basis: auto;
  flex-grow: 1;
  display: grid;

  > span {
    width: 100%;
    max-width: 100%;
  }
}
.theme-row .clickable-theme span.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.base-theme {
  display: flex;
}
.subtheme {
  display: flex;
  margin: 5px 0;
}
.base-theme {
  padding-right: 20px;
}
</style>
