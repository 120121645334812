import { IntegrationType } from 'api/enums';
import DataSourceAircallCreateForm from './DataSourceCreationForms/DataSourceAircallCreate';
import DataSourceAlchemerCreateForm from './DataSourceCreationForms/DataSourceAlchemerCreate';
import DataSourceAppStoreCreateForm from './DataSourceCreationForms/DataSourceAppStoreCreate';
import DataSourceAskNicelyCreateForm from './DataSourceCreationForms/DataSourceAskNicelyCreate';
import DataSourceDelightedCreateForm from './DataSourceCreationForms/DataSourceDelightedCreate';
import DataSourceDiscordCreateForm from './DataSourceCreationForms/DataSourceDiscordCreate';
import DataSourceDiscourseCreateForm from './DataSourceCreationForms/DataSourceDiscourseCreate';
import DataSourceFeefoCreateForm from './DataSourceCreationForms/DataSourceFeefoCreate';
import DataSourceGongCreateForm from './DataSourceCreationForms/DataSourceGongCreate';
import DataSourceGoogleBigQueryCreateForm from './DataSourceCreationForms/DataSourceGoogleBigQueryCreateForm';
import DataSourceGoogleSheetsCreateForm from './DataSourceCreationForms/DataSourceGoogleSheetsCreateForm';
import DataSourceGorgiasCreateForm from './DataSourceCreationForms/DataSourceGorgiasCreate';
import DataSourceIntercomCreateForm from './DataSourceCreationForms/DataSourceIntercomCreate';
import DataSourceJotFormCreateForm from './DataSourceCreationForms/DataSourceJotFormCreate';
import DataSourceQualtricsCreateForm from './DataSourceCreationForms/DataSourceQualtricsCreate';
import DataSourceQuestionProCreateForm from './DataSourceCreationForms/DataSourceQuestionProCreate';
import DataSourceRedditCreateForm from './DataSourceCreationForms/DataSourceRedditCreate';
import DataSourceReevooCreateForm from './DataSourceCreationForms/DataSourceReevooCreate';
import DataSourceSlackCreateForm from './DataSourceCreationForms/DataSourceSlackCreate';
import DataSourceSnowflakeReaderCreateForm from './DataSourceCreationForms/DataSourceSnowflakeReaderCreate';
import DataSourceSurveyMonkeyCreateForm from './DataSourceCreationForms/DataSourceSurveyMonkeyCreate';
import DataSourceSurvicateCreateForm from './DataSourceCreationForms/DataSourceSurvicateCreate';
import DataSourceTrustpilotCreateForm from './DataSourceCreationForms/DataSourceTrustpilotCreate';
import DataSourceTwitterCreateForm from './DataSourceCreationForms/DataSourceTwitterCreate';
import DataSourceTypeformCreateForm from './DataSourceCreationForms/DataSourceTypeformCreate';
import DataSourceYotpoCreateForm from './DataSourceCreationForms/DataSourceYotpoCreate';
import DataSourceZendeskCreateForm from './DataSourceCreationForms/DataSourceZendeskCreate';

export const supportedDataSources = {
  'aircall': {
  name: 'Aircall',
  formComponent: DataSourceAircallCreateForm,
  requiresIntegration: IntegrationType.AIRCALL,
  requiresCommentConfig: false
  },
  'survey_gizmo': {
    name: 'Alchemer',
    formComponent: DataSourceAlchemerCreateForm,
    requiresIntegration: IntegrationType.SURVEYGIZMO,
    requiresCommentConfig: true
  },
  'appstore': {
    name: 'App Store Reviews',
    formComponent: DataSourceAppStoreCreateForm,
    requiresCommentConfig: false
  },
  'asknicely': {
    name: 'AskNicely',
    formComponent: DataSourceAskNicelyCreateForm,
    requiresIntegration: IntegrationType.ASKNICELY,
    requiresCommentConfig: false
  },
  'delighted': {
    name: 'Delighted',
    formComponent: DataSourceDelightedCreateForm,
    requiresIntegration: IntegrationType.DELIGHTED,
    requiresCommentConfig: true
  },
  'discord': {
    name: 'Discord',
    formComponent: DataSourceDiscordCreateForm,
    requiresIntegration: IntegrationType.DISCORD,
    requiresCommentConfig: true
  },
  'discourse': {
    name: 'Discourse',
    formComponent: DataSourceDiscourseCreateForm,
    requiresCommentConfig: true
  },
  'feefo': {
    name: 'Feefo',
    formComponent: DataSourceFeefoCreateForm,
    requiresIntegration: IntegrationType.FEEFO,
    requiresCommentConfig: true
  },
  'gong': {
    name: 'Gong',
    formComponent: DataSourceGongCreateForm,
    requiresIntegration: IntegrationType.GONG,
    requiresCommentConfig: false
  },
  'google_bigquery': {
    name: 'Google BigQuery',
    formComponent: DataSourceGoogleBigQueryCreateForm,
    requiresIntegration: IntegrationType.GOOGLEBIGQUERY,
    requiresCommentConfig: true
  },
  'google_sheets': {
    name: 'Google Sheets',
    formComponent: DataSourceGoogleSheetsCreateForm,
    requiresIntegration: IntegrationType.GOOGLESHEETS,
    requiresCommentConfig: true
  },
  'gorgias': {
    name: 'Gorgias',
    formComponent: DataSourceGorgiasCreateForm,
    requiresIntegration: IntegrationType.GORGIAS,
    requiresCommentConfig: true
  },
  'intercom': {
    name: 'Intercom',
    formComponent: DataSourceIntercomCreateForm,
    requiresIntegration: IntegrationType.INTERCOM,
    requiresCommentConfig: true
  },
  'jotform': {
    name: 'JotForm',
    formComponent: DataSourceJotFormCreateForm,
    requiresIntegration: IntegrationType.JOTFORM,
    requiresCommentConfig: true
  },
  'qualtrics': {
    name: 'Qualtrics',
    formComponent: DataSourceQualtricsCreateForm,
    requiresIntegration: IntegrationType.QUALTRICS,
    requiresCommentConfig: true
  },
  'questionpro': {
    name: 'QuestionPro',
    formComponent: DataSourceQuestionProCreateForm,
    requiresIntegration: IntegrationType.QUESTIONPRO,
    requiresCommentConfig: true
  },
  'reddit': {
    name: 'Reddit',
    formComponent: DataSourceRedditCreateForm,
    requiresCommentConfig: true
  },
  'reevoo': {
    name: 'Reevoo',
    formComponent: DataSourceReevooCreateForm,
    requiresIntegration: IntegrationType.REEVOO,
    requiresCommentConfig: true
  },
  'slack': {
    name: 'Slack Conversations',
    formComponent: DataSourceSlackCreateForm,
    requiresIntegration: IntegrationType.SLACK,
    requiresCommentConfig: true
  },
  'snowflake_reader': {
    name: 'Snowflake (Reader)',
    formComponent: DataSourceSnowflakeReaderCreateForm,
    requiresIntegration: IntegrationType.SNOWFLAKEREADER,
    requiresCommentConfig: true
  },
  'survey_monkey': {
    name: 'Survey Monkey',
    formComponent: DataSourceSurveyMonkeyCreateForm,
    requiresIntegration: IntegrationType.SURVEYMONKEY,
    requiresCommentConfig: true
  },
  'survicate': {
    name: 'Survicate',
    formComponent: DataSourceSurvicateCreateForm,
    requiresIntegration: IntegrationType.SURVICATE,
    requiresCommentConfig: true
  },
  'trustpilot': {
    formComponent: DataSourceTrustpilotCreateForm,
    requiresIntegration: IntegrationType.TRUSTPILOT,
    name: 'Trustpilot',
    requiresCommentConfig: true
  },
  'typeform': {
    name: 'Typeform',
    formComponent: DataSourceTypeformCreateForm,
    requiresIntegration: IntegrationType.TYPEFORM,
    requiresCommentConfig: true
  },
  'twitter': {
    name: 'Twitter',
    formComponent: DataSourceTwitterCreateForm,
    requiresCommentConfig: true
  },
  'yotpo': {
    name: 'Yotpo',
    formComponent: DataSourceYotpoCreateForm,
    requiresIntegration: IntegrationType.YOTPO,
    requiresCommentConfig: true
  },
  'zendesk': {
    name: 'Zendesk',
    formComponent: DataSourceZendeskCreateForm,
    requiresIntegration: IntegrationType.ZENDESK,
    requiresCommentConfig: true
  }
};